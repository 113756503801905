import type { RequestModel, UpdateRequestStateModel, UpdateRequestStatusModel } from './model/requestModel'
import { requestConstants } from '@/models/constants'
import Net from '@/services/net'
import utils from '@/services/utils'

/**
 * Get list of seasonless requests
 * @param requestState request state
 * @returns RequestModel[]
 */
export function getSeasonlessRequests(requestState?: number, status?: number, page?: number, pageSize?: number) {
  const params = new URLSearchParams()
  params.set('requestversion', requestConstants.requestVersion.toString())
  if (utils.isDefined(requestState)) {
    params.set('requeststate', requestState.toString())
  }
  if (utils.isDefined(status) && status === 1) {
    params.set('requeststatus', status.toString())
  }
  if (utils.isDefined(page)) {
    params.set('page', page.toString())
  }
  if (utils.isDefined(pageSize)) {
    params.set('pageSize', pageSize.toString())
  }
  return Net.t1.get<RequestModel[]>(`/requests?${params.toString()}`)
}

export function updateSeasonlessRequestStatuses(payload: UpdateRequestStatusModel[]) {
  return Net.t1.put(`/requests/status`, payload)
}

export function updateSeasonlessRequestsState(payload: UpdateRequestStateModel[]) {
  return Net.t1.put(`/requests/states`, payload)
}
