<template>
  <div>
    <div class="flex flex-nowrap">
      <div class="flex-shrink-0" :style="{ flex: 2 }">
        <tx-autocomplete
          ref="refArticleColor" :model-value="localModelValue" :label="label" :placeholder="placeholder"
          :fetch-suggestions="searchColorPallet" value-prop="ColorId" display-prop="ColorName" :errors="errors"
          :required="required" :disabled="disabled" :clearable="clearable" @change="onChange" @input="onInput"
        >
          <template #item="{ item }">
            <div class="flex pl-2 truncate grow">
              <span
                class="inline-block w-4 h-4 m-auto shadow-card grow-0"
                :style="{ 'background-color': item.HexCode }"
              />
              <span v-if="isPrimaryColor" class="px-1 leading-9 truncate grow">{{ item.Code ? item.Code.toString().padStart(3, 0) : item.Code }} - {{ item.ColorName }}</span>
              <span v-else class="px-1 leading-9 truncate grow">{{ item.ColorName }} </span>
            </div>
          </template>
        </tx-autocomplete>
      </div>
      <div
        v-if="!hideArticleNumberToBeAssigned && userHasPrivilegeToGetUpdatedArticleNumber && isPrimaryColor && (contextArticle?.ColorId == null || contextArticle?.ColorId === 0) && !disabled"
        class="relative flex items-center ml-1 flex-shrink-1" :style="{ flex: 1 }"
      >
        <loader v-show="isLoading" :width="25" :height="25" />
        <span v-show="!isLoading" class="pl-2 select-all">{{ articleNumberToBeAssigned }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import type { ErrorObject } from '@vuelidate/core'
import { uniqBy } from 'lodash-es'
import TxAutocomplete from './TxAutocomplete.vue'
import appConfig from '@/services/appConfig'
import type Article from '@/models/article'
import type ColorPallet from '@/models/colorPallet'
import type ColorPalletColor from '@/models/colorPalletColor'
import Loader from '@/shared/components/Loader.vue'
import type MyArticle from '@/models/myArticle'
import utils from '@/services/utils'
import { getArticleNumberToBeAssigned } from '@/api/t1/article'
import { appConstants, privileges } from '@/models/constants'
import { useUserStore } from '@/store/userData'

interface IProps {
  modelValue: string | null | undefined
  errors?: ErrorObject[]
  form?: Record<string, any>
  label?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  clearable?: boolean
  isPrimaryColor?: boolean
  colorName?: string
  articles?: Array<MyArticle | Article | Record<string, any>>
  preFillColor?: boolean
  hideArticleNumber?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  errors: () => [] as ErrorObject[],
  form: () => ({}),
  label: '',
  placeholder: '',
  required: false,
  clearable: false,
  disabled: false,
  isPrimaryColor: false,
  preFillColor: true,
  hideArticleNumber: false,
  articles: () => ([]),
})

const emit = defineEmits<{
  (e: 'update:modelValue', val: Record<number, string> | null): void
  (e: 'change', val: Record<number, string> | null): void
}>()

const userStore = useUserStore()

let colorPallets: ColorPallet[] = []
const refArticleColor = ref<InstanceType<typeof TxAutocomplete>>()
const articleNumberToBeAssigned = ref<number | null>(null)
const isLoading = ref(false)

const userHasPrivilegeToGetUpdatedArticleNumber = computed(() => userStore.userProfile.isValidPrivilege(privileges.article.GetNextArticleNumberFromSequence))

const contextArticle = computed(() => props.articles ? props.articles[0] : undefined)
const hideArticleNumberToBeAssigned = computed(() => props.hideArticleNumber ? props.hideArticleNumber : false)

const validColorPalettesColor = computed(() => {
  let validColorList: ColorPalletColor[] = []
  const criteriaData: Record<string, any> = {}
  Object.assign(criteriaData, props.articles[0])
  Object.assign(criteriaData, props.form)
  colorPallets.forEach((colorPallet) => {
    let validPallet = true
    for (const criteriaProperty in colorPallet.ColorCriteria) {
      if (!criteriaData.hasOwnProperty(criteriaProperty) || criteriaData[criteriaProperty] !== colorPallet.ColorCriteria[criteriaProperty]) {
        validPallet = false
        break
      }
    }
    if (validPallet) {
      validColorList.push(...colorPallet.Colors)
    }
  })
  validColorList = uniqBy(validColorList, 'ColorId')
  validColorList.sort((a, b) => a.ColorName.localeCompare(b.ColorName))
  return validColorList
})

const localModelValue = computed({
  get: () => {
    let value: string | null = null
    if (props.preFillColor && props.isPrimaryColor) { // in this case component will be read only and display assigned color name
      if ((contextArticle.value && contextArticle.value.ColorId != null && contextArticle.value.ColorId !== 0)) {
        value = contextArticle.value.ColorName
        const colorId = Number(contextArticle.value!.ColorId)
        if (validColorPalettesColor.value.length) {
          const colorPalletColor = validColorPalettesColor.value.find(color => color.ColorId === colorId)
          if (!colorPalletColor) {
            value += ` (Not In Pallet)`
          }
        }
      }
      else if (props.form && props.form._isPlaceholder && !props.form._isPlaceholder) { // carryover colorpalatte fields should be copied from the carryovering article
        value = props.form.ColorName
        const colorId = Number(props.form.ColorId)
        if (validColorPalettesColor.value.length) {
          const colorPalletColor = validColorPalettesColor.value.find(color => color.ColorId === colorId)
          if (!colorPalletColor) {
            value += ` (Not In Pallet)`
          }
        }
      }
    }
    else if (props.modelValue) {
      value = props.modelValue
      const parsedValue = utils.tryParse(props.modelValue)
      if (parsedValue && typeof parsedValue === 'object') {
        const values = Object.values(parsedValue) as string[]
        value = values.length > 0 ? values[0] : ''
      }
    }
    if (refArticleColor.value) {
      refArticleColor.value.setDisplayModelValue(value || '')
    }
    return value
  },
  set: (value) => {
    emit('update:modelValue', value)
    emit('change', value)
  },
})

function searchColorPallet(query: string, callback) {
  if (query) {
    let results = validColorPalettesColor.value.filter(color => color.ColorName.toLowerCase().includes(query.trim().toLowerCase())).slice(0, 50)
    // if exact match present make it as the first entry
    const exactMatch = validColorPalettesColor.value.find(item => item.ColorName.toLowerCase() === query.trim().toLowerCase())
    if (exactMatch) {
      if (!results.find(color => color.ColorName.toLowerCase() === query.trim().toLowerCase())) {
        results.unshift(exactMatch)
      }
      else {
        results = results.filter(color => color.ColorName.toLowerCase() !== exactMatch.ColorName.toLowerCase())
        results.unshift(exactMatch)
      }
    }
    callback(results)
  }
  else {
    callback(validColorPalettesColor.value.slice(0, 50))
  }
}

function onChange(modelValue?: string | number, selectedOption?: ColorPalletColor) {
  if (selectedOption) {
    localModelValue.value = JSON.stringify({ [selectedOption.ColorId]: selectedOption.ColorName })
    // if field is Primary color, populate suggested article number if user has privilege and article is a placeholder article
    if (!hideArticleNumberToBeAssigned.value && userHasPrivilegeToGetUpdatedArticleNumber.value && props.isPrimaryColor && contextArticle.value && (contextArticle.value.ColorId == null || contextArticle.value.ColorId === 0)) {
      initArticleNumberToBeAssigned(selectedOption.ColorId)
    }
  }
  else {
    localModelValue.value = null
    // if field is Primary color, remove suggested article number
    if (props.isPrimaryColor && userHasPrivilegeToGetUpdatedArticleNumber && contextArticle.value && (contextArticle.value.ColorId == null || contextArticle.value.ColorId === 0)) {
      articleNumberToBeAssigned.value = null
    }
  }
}

const debouncedUpdateModelFunction = useDebounceFn((inputValue) => {
  if (inputValue) {
    const colorPalletColor = validColorPalettesColor.value.find(color => color.ColorName.toLowerCase() === inputValue.trim().toLowerCase() || color.ColorId === inputValue.trim())
    if (colorPalletColor) {
      const objectVal: Record<number, string> = {}
      objectVal[colorPalletColor.ColorId] = colorPalletColor.ColorName
      localModelValue.value = JSON.stringify(objectVal)
      // if field is Primary color, populate suggested article number if user has privilege and article is a placeholder article
      if (!hideArticleNumberToBeAssigned.value && userHasPrivilegeToGetUpdatedArticleNumber.value && props.isPrimaryColor && contextArticle.value && (contextArticle.value.ColorId == null || contextArticle.value.ColorId === 0)) {
        initArticleNumberToBeAssigned(colorPalletColor.ColorId)
      }
    }
  }
  else {
    localModelValue.value = null
    // if field is Primary color, remove suggested article number
    if (props.isPrimaryColor && userHasPrivilegeToGetUpdatedArticleNumber && contextArticle.value && (contextArticle.value.ColorId == null || contextArticle.value.ColorId === 0)) {
      articleNumberToBeAssigned.value = null
    }
  }
}, appConstants.debounce.input)

function onInput(inputValue?: string | number) {
  debouncedUpdateModelFunction(inputValue)
}

function initArticleNumberToBeAssigned(colorId) {
  isLoading.value = true
  if (!hideArticleNumberToBeAssigned.value && userStore.activeCatalog && userHasPrivilegeToGetUpdatedArticleNumber.value && props.isPrimaryColor && contextArticle.value && (contextArticle.value.ColorId == null || contextArticle.value.ColorId === 0)) {
    getArticleNumberToBeAssigned(userStore.activeCatalog.CatalogCode, colorId, contextArticle.value.ModelId, contextArticle.value.Id)
      .then((response) => {
        articleNumberToBeAssigned.value = response?.data?.ArticleNumber ? response.data.ArticleNumber : ''
      })
      .catch((error) => {
        console.error(('e.assignColor.failedToFetch'), error)
      })
      .finally(() => {
        isLoading.value = false
      })
  }
}

watch(() => props.modelValue, (modelValue) => {
  if (!utils.isValidStringValue(modelValue)) {
    articleNumberToBeAssigned.value = null
  }
})
onMounted(async () => {
  if (userStore.activeCatalog) {
    colorPallets = await appConfig.DB!.colorPallets.where('CatalogCode').equals(userStore.activeCatalog.CatalogCode).filter(colorPallet => colorPallet.Status > 0).toArray()
  }
  // existing color palatte values contains only color name so when mounted set them in the object format
  if (props.modelValue) {
    if (props.isPrimaryColor && !Number.isNaN(props.modelValue)) {
      if (!utils.isDefined(contextArticle.value)) {
        const colorPalletColor = validColorPalettesColor.value.find(color => color.ColorId === Number(props.modelValue))
        if (colorPalletColor && refArticleColor.value) {
          refArticleColor.value.setDisplayModelValue(colorPalletColor.ColorName)
        }
        else {
          refArticleColor.value?.setDisplayModelValue(`${props.colorName} (Not In Pallet)`)
        }
      }
    }
    else if (typeof props.modelValue === 'string') {
      const colorPalletColor = validColorPalettesColor.value.find(color => color.ColorName.toLowerCase() === props.modelValue!.trim().toLowerCase())
      if (colorPalletColor) {
        const objectVal: Record<number, string> = {}
        objectVal[colorPalletColor.ColorId] = colorPalletColor.ColorName
        emit('update:modelValue', JSON.stringify(objectVal))
      }
    }
  }
})
onUnmounted(() => {
  colorPallets = []
})
</script>
