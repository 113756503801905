<template>
  <div class="border rounded first-of-type:border-t-[1px] border-[#e2e8f06b] mb-1">
    <!-- TITLE -->
    <div :class="headerClass" class="flex justify-between cursor-pointer h-12 leading-[48px] items-center px-4 bg-gray-100 border border-[#e2e8f06b]" @click="onHeaderClicked">
      <span :class="titleClass">{{ title }}</span>
      <tx-button type="plainIcon" icon-size="12px" class="chevron-icon" :class="{ rotate: isActive }" faicon="fa-light fa-chevron-right" />
    </div>
    <!-- CONTENT -->
    <Transition name="accordion" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
      <div v-show="isActive" class="collapse-container border-t-[1px] border-[#e2e8f06b] border-l-[1px] border-r-[1px]">
        <slot />
      </div>
    </Transition>
  </div>
</template>

<script lang='ts' setup>
import type { Ref } from 'vue'
import { computed, inject } from 'vue'
import TxButton from '@/shared/components/TxButton.vue'

const props = defineProps<{
  title: string
  value: string | number
  titleClass?: string
  headerClass?: string
}>()

const { activeItems, updateActiveItems } = inject('txCollapse') as { activeItems: Ref<Array<string | number>>, updateActiveItems: (value: string | number) => void }

// COMPUTED
const isActive = computed(() => activeItems.value.includes(props.value))
// COMPUTED - END

// METHODS
function onHeaderClicked() {
  updateActiveItems(props.value)
}

function beforeEnter(el) {
  el.style.height = '0'
  el.style.opacity = 0
}
function enter(el) {
  el.style.height = `${el.scrollHeight}px`
  el.style.opacity = 1
}
function beforeLeave(el) {
  el.style.height = `${el.scrollHeight}px`
  el.style.opacity = 1
}
function leave(el) {
  el.style.height = '0'
  el.style.opacity = 0
}
// METHODS - END
</script>

<style lang='scss' scoped>
.collapse-container {
  transition: 150ms ease-out;
}

.chevron-icon {
  transform: rotate(0deg);
  transition-duration: 150ms;

  &.rotate {
    transform: rotate(90deg);
    transition-duration: 150ms;
  }
}
</style>
