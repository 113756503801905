<template>
  <tx-dialog
    v-model="visible" :title="t('assignFile.confirmation', { articleNumber: localAssetModel?.ImageSet })" width="600px"
    show-ok-cancel :cancel-state="loading ? 'disabled' : 'enabled'" :ok-state="loading ? 'loading' : v$.$invalid ? 'disabled' : 'enabled'"
    @click="doCancel()" @ok="doConfirm()"
  >
    <div class="flex flex-col w-full h-full">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
      <div class="grow flex">
        <div class="grow">
          <div class="text-base">
            {{ t('assignFile.imageOrResource') }}
          </div>
          <div class="flex flex-col gap-2 mt-2">
            <label v-if="allowImage">
              <input v-model="form.source" type="radio" value="image">
              <span class="ml-1">{{ t('assignFile.image') }}</span>
            </label>
            <label v-if="allowResource && imageResourceType">
              <input v-model="form.source" type="radio" value="resource">
              <span class="ml-1">{{ t('assignFile.resource') }}</span>
            </label>
          </div>
          <div v-show="form.source === 'resource'" class="mt-4">
            <div class="w-full my-2">
              <tx-input
                v-model="form.resourceName" :label="t('createResourceForm.resourceName')"
                :errors="v$.resourceName.$errors" required @blur="v$.resourceName.$touch"
              />
            </div>
            <div class="w-full my-2">
              <tx-input
                v-model="form.resourceDescription" :label="t('createResourceForm.description')"
                :errors="v$.resourceDescription.$errors" @blur="v$.resourceDescription.$touch"
              />
            </div>
          </div>
          <div v-show="form.source === 'image' && configuredImagekeys && configuredImagekeys.length > 1" class="mt-4">
            <div class="w-full my-2">
              <tx-select
                v-model="form.imageKey" :label="t('assignFile.selectImageKey')" required :data="configuredImagekeys"
              />
            </div>
          </div>
        </div>
        <img :src="previewImg" class="mx-auto w-60 h-60">
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref } from 'vue'
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, maxLength, minLength, required } from '@vuelidate/validators'
import { assign, cloneDeep } from 'lodash-es'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxInput from '@/shared/components/TxInput.vue'
import type MyArticle from '@/models/myArticle'
import type { CreateUpdateAssetModel } from '@/api/dune/model/assetModel'
import { createAsset } from '@/api/dune/asset'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import { assignArticlesToResource, createResourceWithArticlesOptional } from '@/api/t1/resource'
import Resource from '@/models/resource'
import appConfig from '@/services/appConfig'
import type { CreateResourceWithArticlesOptionalModel } from '@/api/t1/model/resourceModel'
import auth from '@/services/auth'
import Net from '@/services/net'
import utils from '@/services/utils'
import type ResourceType from '@/models/resourceType'
import TxSelect from '@/shared/components/TxSelect.vue'

interface IAssignImageForm {
  source: 'image' | 'resource'
  resourceName: string
  resourceDescription: string
  imageKey: string
}

interface IProps {
  allowImage?: boolean
  allowResource?: boolean
}

const props = withDefaults(defineProps<IProps>(), { allowImage: true, allowResource: true })

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'confirm', article: MyArticle, asset?: string): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()
const withI18nMessage = createI18nMessage({ t })
const visible = ref(false)
const loading = ref(false)
const configuredImagekeys = userStore.activeCatalog?.Config.WhiteboardImagePlaceholderOptions && userStore.activeCatalog?.Config.WhiteboardImagePlaceholderOptions.keys.length ? userStore.activeCatalog?.Config.WhiteboardImagePlaceholderOptions.keys : []

const initialFormState: IAssignImageForm = {
  source: 'image',
  resourceName: '',
  resourceDescription: '',
  imageKey: configuredImagekeys.length ? configuredImagekeys[0] : '',
}

const form = reactive<IAssignImageForm>({ ...initialFormState })

const localArticle = ref<MyArticle>()
const localAssetModel = ref<CreateUpdateAssetModel>()
const imageResourceType = ref<ResourceType>()

const rules = computed(() => {
  const result: Record<string, any> = {
    source: { required: withI18nMessage(required) },
    resourceName: {},
    resourceDescription: {},
  }

  if (form.source === 'resource') {
    result.resourceName = { required: withI18nMessage(required), minLength: withI18nMessage(minLength(1)), maxLength: withI18nMessage(maxLength(250)) }
    result.resourceDescription = { maxLength: withI18nMessage(maxLength(2000)) }
  }

  return result
})
const v$ = useVuelidate(rules, form)

const previewImg = computed(() => {
  if (localAssetModel.value) {
    return URL.createObjectURL(localAssetModel.value.file)
  }
  return ''
})

function showDialog(article: MyArticle, assetModel: CreateUpdateAssetModel) {
  reset()
  if (props.allowImage) {
    form.source = 'image'
  }
  else if (props.allowResource) {
    form.source = 'resource'
  }
  visible.value = true
  localArticle.value = article
  localAssetModel.value = assetModel

  if (appConfig.DB && userStore.activeCatalog && utils.isValidStringValue(userStore.activeCatalog?.Config.WhiteboardDefaultResourceType)) {
    loading.value = true
    appConfig.DB.resourceTypes.get({ Type: userStore.activeCatalog.Config.WhiteboardDefaultResourceType })
      .then((resourceType) => {
        imageResourceType.value = resourceType
      })
      .finally(() => {
        loading.value = false
      })
  }
}

function doCancel() {
  visible.value = false
  emit('cancel')
}

function reset() {
  loading.value = false
  errorMessage.value = ''
  assign(form, cloneDeep(initialFormState))
}

async function doConfirm() {
  errorMessage.value = ''
  if (!(await v$.value.$validate())) {
    errorMessage.value = t('validations.formInvalid')
    return
  }
  if (localAssetModel.value && localArticle.value) {
    if (localAssetModel.value.Key !== form.imageKey) {
      const fileExtension = localAssetModel.value.file.name.split('.').pop()
      const newFilename = `${localAssetModel.value.ImageSet}_${form.imageKey}.${fileExtension}`

      localAssetModel.value = {
        ...localAssetModel.value,
        Key: form.imageKey,
        file: new File([localAssetModel.value.file], newFilename, { type: localAssetModel.value.file.type }),
      }
    }

    if (form.source === 'image') {
      loading.value = true
      createAsset(localAssetModel.value)
        .then(async (res) => {
          await userStore.doLoadData(['Articles'])
          emit('confirm', localArticle.value!, res.data.Id)
          visible.value = false
        })
        .catch((e) => {
          console.error(e)
          errorMessage.value = t('general.unexpectedError')
        })
        .finally(() => {
          loading.value = false
        })
    }
    else if (form.source === 'resource' && imageResourceType.value) {
      if (userStore.activeCatalog && localArticle.value) {
        const catalogCode = userStore.activeCatalog.CatalogCode
        loading.value = true
        try {
          const existingResource = await appConfig.DB!.resources.where({ CatalogCode: catalogCode, ResourceName: form.resourceName, ResourceTypeId: imageResourceType.value.Id }).first()
          if (existingResource) {
            if (!existingResource.Articles.includes(localArticle.value.Id)) {
              existingResource.Articles.push(localArticle.value.Id)
              assignArticlesToResource(catalogCode, existingResource.ResourceId, existingResource.Articles)
                .then(async () => {
                  await appConfig.DB!.resources.put(existingResource)
                  emit('confirm', localArticle.value!)
                  visible.value = false
                })
                .catch((e) => {
                  console.error(e)
                  if (e.response?.data) {
                    errorMessage.value = e.response.data.map(x => x.ErrorMessage).join(', ')
                  }
                  else {
                    errorMessage.value = t('general.unexpectedError')
                  }
                })
                .finally(() => {
                  loading.value = false
                })
            }
            else {
              visible.value = false
              loading.value = false
            }
          }
          else {
            const payload: CreateResourceWithArticlesOptionalModel = {
              TypeId: imageResourceType.value.Id,
              ResourceName: form.resourceName,
              Description: form.resourceDescription,
              FileId: await utils.uploadFlowFile(`${Net.t1.defaults.baseURL}/UploadFileChunk`, auth.getLocalToken() || '', localAssetModel.value.file),
              IconFileId: null,
              Category: 'Support Imagery',
              Cascading: 0,
              SortOrder: 0,
              ArticleIds: [localArticle.value.Id],
            }

            createResourceWithArticlesOptional(catalogCode, payload)
              .then(async (res) => {
                const newResource = new Resource(catalogCode, res.data)
                await appConfig.DB!.resources.add(newResource)
                emit('confirm', localArticle.value!)
                visible.value = false
              })
              .catch((e) => {
                console.error(e)
                if (e.response?.data) {
                  errorMessage.value = e.response.data.map(x => x.ErrorMessage).join(', ')
                }
                else {
                  errorMessage.value = t('general.unexpectedError')
                }
              })
              .finally(() => {
                loading.value = false
              })
          }
        }
        catch (e) {
          console.error(e)
          errorMessage.value = t('general.unexpectedError')
          loading.value = false
        }
      }
    }
  }
}

defineExpose({
  showDialog,
})
</script>
