<template>
  <tx-dialog
    v-model="visible" :title="t('saveImageDialog.title')" width="450px" height="300px" :loading="loading"
    show-ok-cancel :ok-state="loading ? 'loading' : selectedImageTypes.length === 0 ? 'disabled' : 'enabled'"
    @click="doCancel()" @ok="doSave()"
  >
    <div class="w-full h-full">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
      <div class="w-full h-full">
        <div class="w-full">
          <tx-select
            v-model="selectedImageTypes" :label="t('saveImageDialog.selectImageTypes')" required clearable multiple-values
            :data="availableImageTypes" value-prop="key" display-prop="label"
          />
        </div>
        <div v-if="isFromWhiteboard && isResourcesModuleEnabled" class="w-full">
          <tx-switch v-model="downloadResourceImages" class="w-auto h-5" :label="t('saveImageDialog.downloadReourceImages')" />
        </div>
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { computed, onUnmounted, ref } from 'vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import type MyArticle from '@/models/myArticle'
import DuneAsset from '@/models/duneAsset'
import { getArticlesAssets } from '@/api/t1/article'
import utils from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import type Article from '@/models/article'
import useDisableModule from '@/shared/composables/disableModule'
import TxSwitch from '@/shared/components/TxSwitch.vue'

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'save'): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()

const visible = ref(false)
const loading = ref(false)
const assetsMap = ref<Record<string, DuneAsset[]>>({})
const imageTypes = ref<Map<string, string>>(new Map())
const selectedImageTypes = ref<string[]>([])
const fileNamesForSave = new Map<string, number>()
const isFromWhiteboard = ref(false)
let selectedArticles: MyArticle[] | Article[] = []
const resourceType = userStore.activeCatalog?.Config.WhiteboardDefaultResourceType
const { isResourcesModuleEnabled } = useDisableModule()
const downloadResourceImages = ref(true)

const availableImageTypes = computed<IKeyLabel[]>(() => Array.from(imageTypes.value, (item) => { return { key: item[0], label: item[1] } }))

async function showDialog(articles: MyArticle[] | Article[], isWhiteboard: boolean = false) {
  reset()
  loading.value = true
  selectedArticles = articles
  isFromWhiteboard.value = isWhiteboard
  const res = await getArticlesAssets(userStore.activeCatalog!.DuneContext, userStore.activeCatalog!.ContextKey, articles.map(s => s.ArticleNumber))
  if (res.data && res.data.length) {
    const assets = res.data
    if (assets && assets.length > 0) {
      assets.forEach((asset) => {
        const key = asset.Key.toLowerCase()
        if (!imageTypes.value.has(key)) {
          imageTypes.value.set(key, asset.Key)
          selectedImageTypes.value.push(key)
        }
        if (!assetsMap.value[key]) {
          assetsMap.value[key] = [asset]
        }
        else {
          assetsMap.value[key].push(asset)
        }
      })
    }
  }
  loading.value = false
}

function doCancel() {
  visible.value = false
  emit('cancel')
}

function getFileNameToSave(asset: DuneAsset) {
  let fileName = asset.FileName
  if (userStore.activeCatalog && utils.isValidStringValue(userStore.activeCatalog.Config.SaveImagesFileName)) {
    const evalFunction = `function(data){ return ${userStore.activeCatalog.Config.SaveImagesFileName}}`
    // eslint-disable-next-line no-new-func
    const tempFileName = Function(`'use strict';return (${evalFunction})`)()(asset)
    const count = fileNamesForSave.get(fileName) || 0
    fileNamesForSave.set(fileName, count + 1)
    fileName = count !== 0 ? `${tempFileName}(${count})` : tempFileName
  }
  else {
    const name = fileName.split('.').slice(0, -1).join('.')
    fileName = `${name}(${asset.Key})`
  }
  return fileName
}

async function doSave() {
  errorMessage.value = ''
  if (selectedImageTypes.value.length && userStore.activeCatalog) {
    loading.value = true
    fileNamesForSave.clear()
    const format = userStore.activeCatalog.Config.DownloadArticleImageFormat
    const promises: Promise<any>[] = []
    const selectedImageTypesSet = new Set(selectedImageTypes.value)
    let directoryHandle: FileSystemDirectoryHandle | null = null

    // Ask for directory picker first
    if (window && typeof window.showDirectoryPicker !== 'undefined') {
      try {
        directoryHandle = await window.showDirectoryPicker({ mode: 'readwrite' })
      }
      catch (error) {
        console.error('Error opening directory:', error)
        errorMessage.value = t('general.unexpectedError')
        loading.value = false
        return
      }
    }

    // If Whiteboard: Download Resources also if resource module enabled and resources available
    if (isResourcesModuleEnabled.value && downloadResourceImages.value && isFromWhiteboard.value) {
      selectedArticles.forEach((article) => {
        if (article._Resources && Array.isArray(article._Resources) && article._Resources.length > 0 && resourceType) {
          const validImageResources = article._Resources.filter(r =>
            r.ResourceType.toLowerCase().includes(resourceType.toLowerCase()),
          )

          validImageResources.forEach((res) => {
            // resource file name format
            const fileName = `${article.ArticleNumber}_${res.ResourceName}.${format}`

            const promise = axios({
              url: res.ResourceUrl,
              method: 'GET',
              responseType: 'blob',
            }).then((response) => {
              saveFile(response.data, fileName, directoryHandle)
            })

            promises.push(promise)
          })
        }
      })
    }

    // download selected article images
    selectedImageTypes.value.forEach((key) => {
      if (assetsMap.value[key]) {
        const assets = assetsMap.value[key]
        assets?.forEach((asset) => {
          if (selectedImageTypesSet.has(asset.Key.toLowerCase())) {
            const promise = axios({
              url: `${appConfig.AssetsUrl}/assets/content/${asset.StorageFile}?ContextKey=${encodeURIComponent(userStore.activeCatalog!.ContextKey)}&f=${format}`,
              method: 'GET',
              responseType: 'blob',
            }).then((response) => {
              const duneAsset = new DuneAsset(userStore.activeCatalog!.DuneContext, asset)
              const fileName = `${getFileNameToSave(duneAsset)}.${format}`

              saveFile(response.data, fileName, directoryHandle)
            })

            promises.push(promise)
          }
        })
      }
    })

    Promise.all(promises)
      .then(() => {
        loading.value = false
        visible.value = false
        emit('save')
      })
      .catch((e) => {
        console.error(e)
        errorMessage.value = t('general.unexpectedError')
      })
  }
  else {
    errorMessage.value = t('validations.formInvalid')
  }
}

// Save file to directory or fallback to browser download
async function saveFile(blob: Blob, fileName: string, directoryHandle: FileSystemDirectoryHandle | null) {
  if (directoryHandle) {
    try {
      const fileHandle = await directoryHandle.getFileHandle(fileName, { create: true }) as FileSystemFileHandle
      const writable = await (fileHandle as any).createWritable()
      await writable.write(blob)
      await writable.close()
    }
    catch (error) {
      console.error('Directory Write Error:', error)
    }
  }
  else { // fall back to default download
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

function reset() {
  loading.value = false
  visible.value = true
  imageTypes.value = new Map()
  selectedImageTypes.value = []
  assetsMap.value = {}
}

onUnmounted(() => {
  selectedArticles = []
})

defineExpose({
  showDialog,
})
</script>
