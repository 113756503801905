<template>
  <div
    class="cursor-pointer"
    @click="doToggle"
  >
    <font-awesome-icon :icon="modelValue ? onStateIcon : offStateIcon" class="w-full h-full" />
  </div>
</template>

<script setup lang="ts">
interface IProps {
  onStateIcon: string
  offStateIcon: string
  modelValue: boolean
}
const props = defineProps<IProps>()

const emit = defineEmits<{
  (e: 'toggle', val: boolean): void
  (e: 'update:modelValue', val: boolean): void
}>()

function doToggle() {
  emit('toggle', !props.modelValue)
  emit('update:modelValue', !props.modelValue)
}
</script>
