import type { IFilterCriteria } from './filterCriteria'
import type { ISettingItem } from '@/modules/articleDetails/components/SettingsDropdown.vue'
import type { ISettingItemWhiteboard } from '@/modules/whiteboard/components/Settings.vue'

export default class UserPreferences {
  userId: number
  targetSummary: { filterCriteria: IFilterCriteria[] }
  modelDetails: { settings: Record<number, ISettingItem> }
  whiteboard: { settings: Record<number, ISettingItemWhiteboard> }

  constructor(userPreferences: UserPreferences) {
    this.userId = userPreferences.userId
    this.targetSummary = userPreferences.targetSummary
    this.modelDetails = userPreferences.modelDetails
    this.whiteboard = userPreferences.whiteboard
  }
}
