<template>
  <div class="container relative w-full h-full overflow-hidden">
    <!-- HEADER -->
    <tx-form-header class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header" :title="t('articleDetails.actions.assignResources')" :show-header="showHeader" />

    <!-- Tabs -->
    <div class="w-full px-10">
      <div v-t="'assignArticleResource.subTitle'" class="h-3 mb-2 text-xs leading-3 tracking-wide uppercase" />
      <tx-tabs v-model="activeTab" :tabs="Object.values(tabs)" />
    </div>
    <assign-resource-form v-if="activeTab === tabs.existingResource.key" :articles="articles" :is-model="isModel" @cancel="onCancel" @updated="onUpdate" />
    <create-resource-form v-if="activeTab === tabs.newResource.key" :articles="articles" :is-model="isModel" @cancel="onCancel" @updated="onUpdate" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import TxTabs from '@/shared/components/TxTabs.vue'
import AssignResourceForm from '@/shared/components/resources/AssignResourceForm.vue'
import CreateResourceForm from '@/shared/components/resources/CreateResourceForm.vue'
import type MyArticle from '@/models/myArticle'
import { privileges } from '@/models/constants'
import { useUserStore } from '@/store/userData'
import TxFormHeader from '@/shared/components/forms/TxFormHeader.vue'

interface IProps {
  articles: MyArticle[]
  showHeader?: boolean
  isModel?: boolean
}

withDefaults(defineProps<IProps>(), {
  showHeader: true,
  isModel: false,
})

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated', articles: MyArticle[], type: string): void
}>()

const userStore = useUserStore()
const { t } = useI18n()

const tabs: Record<string, ITabItem> = {
  existingResource: { key: 'existingResource', label: 'assignArticleResource.assignExistingResource', visible: userStore.userProfile.isValidPrivilege(privileges.resource.assign) },
  newResource: { key: 'newResource', label: 'assignArticleResource.addNewResource', visible: userStore.userProfile.isValidPrivilege(privileges.resource.create) },
}
const activeTab = ref<string>('')

function onCancel() {
  emit('cancel')
}

async function onUpdate(articles: MyArticle[], type = '') {
  await userStore.doLoadData(['Resources'])
  emit('updated', articles, type)
}
</script>
