import colorwayMerch from './colorwayMerch'
import type { IWhiteboardTemplate } from './IWhiteboardTemplate'
import LevisMensBottomsRTFinish from './LevisMensBottomsRTFinish'
import standard from './standard'
import visualLineBuilder from './visualLineBuilder'

// TODO: Might need to load them dynamically

const templates: Record<number, IWhiteboardTemplate> = {}
templates[standard.id] = standard
templates[visualLineBuilder.id] = visualLineBuilder
templates[colorwayMerch.id] = colorwayMerch
templates[LevisMensBottomsRTFinish.id] = LevisMensBottomsRTFinish

export default templates
