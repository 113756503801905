<template>
  <template v-if="type === 'icon'">
    <img v-if="utils.isValidStringValue(resource.ResourceIcon)" :src="resource.ResourceIcon">
    <font-awesome-icon v-else :icon="faIcon" class="w-5 h-5" />
  </template>
  <div
    v-else-if="type === 'tile'"
    class="flex items-center space-x-2 p-2 shadow border rounded-lg"
  >
    <img
      v-if="faIcon === 'fa-light fa-image'"
      :src="utils.isValidStringValue(resource.ResourceIcon) ? resource.ResourceIcon : resource.ResourceUrl"
      :alt="resource.ResourceName"
      class="w-12 h-12 rounded-full object-cover"
    >
    <font-awesome-icon v-else :icon="faIcon" class="w-10 h-10" />
    <div>
      <h3 class="text-lg font-semibold">
        {{ resource.ResourceName }}
      </h3>
      <p class="text-sm text-gray-600">
        {{ resource.ResourceDescription }}
      </p>
    </div>
  </div>
  <div
    v-else-if="type === 'card'"
    class="flex flex-col items-center p-4 shadow-card rounded-lg hover:shadow-lg transition-all duration-200"
  >
    <img
      v-if="faIcon === 'fa-light fa-image'"
      :src="utils.isValidStringValue(resource.ResourceIcon) ? resource.ResourceIcon : resource.ResourceUrl"
      :alt="resource.ResourceName"
      class="w-24 h-24 object-scale-down"
    >
    <font-awesome-icon v-else :icon="faIcon" class="w-20 h-20" />
    <h3 class="w-full text-md font-semibold text-center select-all overflow-hidden whitespace-nowrap text-ellipsis">
      {{ resource.ResourceName }}
    </h3>
    <p class="w-full text-sm text-center select-all truncate-2 text-gray-600">
      {{ resource.ResourceDescription }}
    </p>
  </div>
  <div
    v-else
    class="flex items-center space-x-2 p-2 border rounded-lg"
  >
    <h3 class="text-lg font-semibold">
      {{ resource.ResourceName }}
    </h3>
    <p class="text-sm text-gray-600">
      {{ resource.ResourceDescription }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type Resource from '@/models/resource'
import utils from '@/services/utils'

interface IProps {
  type?: 'normal' | 'tile' | 'card' | 'icon'
  resource: Resource
}
const props = withDefaults(defineProps<IProps>(), {
  type: 'normal',
})

const fileExtension = computed(() => props.resource.ResourceUrl.split('.').pop())

const faIcon = computed(() => {
  switch (fileExtension.value) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'tif':
    case 'bmp':
      return 'fa-light fa-image'
    case 'mp4':
      return 'fa-light fa-video'
    case 'pdf':
      return 'fa-light fa-file-pdf'
    case 'xls':
    case 'xlsx':
    case 'xlsb':
      return 'fa-light fa-file-excel'
    case 'doc':
    case 'docx':
    case 'rtf':
      return 'fa-light fa-file-word'
    case 'ppt':
    case 'pptx':
      return 'fa-light fa-file-powerpoint'
    case 'txt':
      return 'fa-light fa-file-text'
    case 'zip':
    case 'rar':
    case '7z':
      return 'fa-light fa-file-zipper'
    default:
      return 'fa-light fa-file'
  }
})
</script>
