<template class="container relative flex flex-col w-full h-full overflow-y-auto">
  <tx-dialog
    v-model="visible" :title="getEditRequestTitle"
    width="60%"
    height="85%"
    show-ok-cancel
    :ok-state="v$.$invalid || loading || isSaveDisabled || isRequestAttachmentsUploading ? 'disabled' : 'enabled'"
    @click="doCancel()" @ok="doSave()"
  >
    <div class="relative w-full h-full overflow-x-hidden">
      <loader v-if="loading" />
      <div v-else class="container flex flex-col w-full h-full">
        <!-- BODY -->
        <div class="px-10 mt-4 alerts">
          <tx-alert :show="showAffectedSeasons" type="info" :text="seasonsDataMessage" />
          <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
        </div>
        <div class="h-full">
          <div class="grid w-full grid-cols-2 px-10 gap-x-6">
            <div
              v-for="(attribute, index) in visibleAttributes"
              :key="attribute.SystemName"
              :class="{
                'col-span-2': (index < 2 || attribute.SystemName === 'SizeScaleId') && updateAttribute?.SystemName !== editRequestKeys.prices,
                'col-span-1': index >= 2 && attribute.SystemName !== 'SizeScaleId',
              }"
              class="mb-5 last-of-type:mb-4"
            >
              <attribute-editor
                v-model="form[attribute.SystemName]"
                :articles="updateArticle ? [updateArticle] : []"
                :attribute="attribute"
                :required="attribute.IsRequired"
                :show-checkbox="false"
                :form="form"
                :errors="v$[attribute.SystemName]?.$errors"
                :disabled="attribute.ReadOnly"
                :should-consider-obsolete-model-value="attribute.AttributeType === AttributeType.MasterSizeScale || attribute.AttributeType === AttributeType.SizeScale"
                :should-allow-reset-existing-value="attribute.SystemName !== 'MasterSizeScaleId'"
                @blur="v$[attribute.SystemName]?.$touch"
                @change="v$[attribute.SystemName]?.$touch"
              />
            </div>
            <div class="mb-5 last-of-type:mb-4 col-span-2">
              <div class="w-full h-3 overflow-hidden text-xs leading-3 tracking-wide uppercase label whitespace-nowrap text-ellipsis">
                {{ t('general.fileList') }}
              </div>
              <upload-attachment
                v-model="form.Attachments"
                :restricted-formats="requestConstants.allowedRequestAttachmentsTypes" :multiple="true"
                @files-uploading="handleAttachmentsUploadingStatus"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { helpers, maxLength, required } from '@vuelidate/validators'
import { v4 as guid } from 'uuid'
import { useUserStore } from '@/store/userData'
import useErrorMessage from '@/shared/composables/errorMessage'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import type MyArticle from '@/models/myArticle'
import utils from '@/services/utils'
import { appConstants, editRequestKeys, requestConstants } from '@/models/constants'
import type CatalogAttribute from '@/models/catalogAttribute'
import { AttributeType } from '@/models/catalogAttribute'
import Loader from '@/shared/components/Loader.vue'
import { createRequestBulk } from '@/api/t1/request'
import type { CreateRequestModel } from '@/api/t1/model/requestModel'
import { useArticleFormHelper } from '@/shared/composables/articleFormHelper'
import AttributeEditor from '@/shared/components/AttributeEditor.vue'
import type { SeasonsDataModel } from '@/api/t1/model/articleModel'
import { getSeasonsData } from '@/api/t1/article'
import appConfig from '@/services/appConfig'
import UploadAttachment from '@/shared/components/UploadAttachment.vue'

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'editRequestCreated'): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()
const form = reactive<Record<string, any>>({})
const { getArticlesMaxStateDetails } = useArticleFormHelper()

const visible = ref(false)
const loading = ref(false)
const updateArticle = ref<MyArticle | null>()
const updateAttribute = ref<IMyAttribute | null>()
const showAffectedSeasons = ref(false)
const affectedSeasons = ref([] as SeasonsDataModel[])
const seasonsDataMessage = ref('')
const skipRestrictingPropertyUpdateBasedOnArticleState: boolean = userStore.userProfile.Permissions.has('SpecialPermissionToIgnoreArticleState')
const isRequestAttachmentsUploading = ref(false)
const isArticlePricesLocked = ref(false)
const IsSeasonlessRequest = ref(0)

const getEditRequestTitle = computed(() => {
  const key = updateAttribute.value?.SystemName
  let displayName = key
  if (key && userStore.myAttributes![key]) {
    displayName = userStore.myAttributes![key].DisplayName
    return `Edit ${displayName} Request`
  }
  else if (key === 'SizeScaleId') {
    return t(requestConstants.requestTypes.EditSizeScale.label)
  }
  return `Edit ${key} Request`
})

const visibleAttributes = computed(() => {
  if (!updateAttribute.value) { return [] }

  const visibleAttributes: IMyAttribute[] = []
  if (userStore.activeCatalog) {
    // When no configuration is found, add all request attributes
    userStore.activeCatalog.RequestAttributeList.forEach((attribute) => {
      if (attribute.Status > 0 && attribute.AttributeTypeId !== AttributeType.Calc
        && utils.isDefined(userStore.myAttributes![attribute.AttributeSystemName])
      ) {
        const requestAttributeField: IMyAttribute = {
          SystemName: attribute.AttributeSystemName,
          DisplayName: attribute.AttributeDisplayName,
          AttributeType: attribute.AttributeTypeId,
          AttributeSource: 'request',
          Creatable: true,
          Editable: true,
          Visible: true,
          IsRequired: true,
          IsStatic: false,
          IsSeasonless: false,
          IsModelLevel: false,
          IsRequest: true,
          Overridable: false,
          ValidationExpression: attribute.ValidationExpression,
          ValidationMessage: attribute.ValidationMessage,
          VettingList: userStore.myAttributes![attribute.AttributeSystemName].VettingList,
          AllowFiltering: userStore.myAttributes![attribute.AttributeSystemName].AllowFiltering,
          FilterLookup: userStore.myAttributes![attribute.AttributeSystemName].FilterLookup,
          Criteria: userStore.myAttributes![attribute.AttributeSystemName].Criteria,
          CriteriaVettingList: userStore.myAttributes![attribute.AttributeSystemName].CriteriaVettingList,
          parsedValidationExpression: userStore.myAttributes![attribute.AttributeSystemName].parsedValidationExpression,
          SortOrder: 0,
          prePopulate: false,
          ReadOnly: false,
          IsPersonal: userStore.myAttributes![attribute.AttributeSystemName].IsPersonal,
        }
        visibleAttributes.push(requestAttributeField)
      }
    })

    const formConfig = userStore.activeCatalog?.Config?.RequestForm
    if (formConfig && utils.isDefined(formConfig.modifyAttributes) && userStore.activeCatalog) {
      const modifyAttributesConfig = formConfig.modifyAttributes[updateAttribute.value!.SystemName]
        || formConfig.modifyAttributes.Default // Check for Default if specific config is missing
      if (modifyAttributesConfig) {
        for (let i = visibleAttributes.length - 1; i >= 0; i--) {
          if (!modifyAttributesConfig.includes(visibleAttributes[i].SystemName)) {
            visibleAttributes.splice(i, 1)
          }
        }
      }
    }
  }
  if (updateAttribute.value.SystemName !== editRequestKeys.prices) {
    const currentValueField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'CurrentValue',
      DisplayName: t('requests.editRequestDialog.currentValue'),
      Creatable: true,
      AttributeType: updateAttribute.value!.AttributeType,
      ReadOnly: true,
      IsRequired: false,
    })
    const requestedValueField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: updateAttribute.value!.SystemName,
      DisplayName: t('requests.editRequestDialog.requestedValue'),
      Creatable: true,
      AttributeType: updateAttribute.value!.AttributeType,
      ReadOnly: false,
      IsRequired: true,
      VettingList: updateAttribute.value!.VettingList,
      maxLength: updateAttribute.value!.SystemName === 'ModelName' ? userStore.sellerDetails?.ModelNameLength || 100 : null,
    })
    visibleAttributes.unshift(requestedValueField)
    visibleAttributes.unshift(currentValueField)
  }
  return visibleAttributes
})

const rules = computed(() => {
  const result: Record<string, any> = {}
  visibleAttributes.value.forEach((attribute) => {
    result[attribute.SystemName] = {}
    if (attribute.IsRequired) {
      result[attribute.SystemName].required = helpers.withMessage(t('validations.required', { property: attribute.DisplayName }), required)
    }
    if (attribute.maxLength != null) {
      result[attribute.SystemName].maxLength = helpers.withMessage(t('validations.maxLength', { property: attribute.DisplayName, max: attribute.maxLength }), maxLength(attribute.maxLength))
    }
  })
  return result
})
const v$ = useVuelidate(rules, form)

const isSaveDisabled = computed(() => {
  if (updateAttribute.value?.SystemName === editRequestKeys.prices) {
    // at least one price group request should have value
    return !Object.keys(form).some((key) => {
      return userStore.activeCatalog!.CatalogPriceGroupList.some(priceGroup => priceGroup.Id === Number(key) && utils.isDefined(form[key]) && form[key] !== '')
    })
  }
  return false
})

async function showDialog(article: MyArticle, attribute: IMyAttribute, isArticleLocked?: boolean) {
  reset()
  loading.value = true

  updateArticle.value = article
  updateAttribute.value = attribute
  isArticlePricesLocked.value = isArticleLocked || false
  form.CurrentValue = article[attribute.SystemName]

  // if seasonless article/model attribute show affected seasons
  if (userStore.activeCatalog && (!attribute.IsStatic || attribute.SystemName === editRequestKeys.modelName || attribute.SystemName === editRequestKeys.sizeScaleId || attribute.SystemName === editRequestKeys.colorId)) {
    const selectedAttribute: CatalogAttribute = userStore.activeCatalog.AssignedCatalogAttributes.filter(attr => attr.AttributeSystemName === attribute.SystemName)[0]
    const isSeasonlessModelAttribute = selectedAttribute ? selectedAttribute.IsSeasonlessModelAttribute || selectedAttribute.IsSeasonless : true // SizeScale, Primary Color and ModelName

    if (isSeasonlessModelAttribute) {
      try {
        // get affected seasons.
        const seasonsData = await getSeasonsData(article.Id)
        affectedSeasons.value = seasonsData.data
        let affectedSeasonsString = ''
        if (affectedSeasons.value.length) {
          const uniqueSeasons = [...new Set(affectedSeasons.value.map(season => season.Season))].join(',')
          affectedSeasonsString = uniqueSeasons
          // add the ImpactedCatalogs in the API request
          form.ImpactedCatalogs = affectedSeasons.value.map((season) => { return season.CatalogCode }).join(',')
        }

        seasonsDataMessage.value = t('requests.editRequestDialog.seasonsDataMessage', { seasons: affectedSeasonsString })
        showAffectedSeasons.value = true
        IsSeasonlessRequest.value = 1
      }
      catch (error) {
        console.error('Failed to fetch seasons data:', error)
        errorMessage.value = t('requests.errors.failedToFetchSeasons')
        showAffectedSeasons.value = false
      }
    }
  }

  // show State dropdown when StateName configured
  if (attribute.SystemName === editRequestKeys.stateName) {
    const activeStates = getActiveStates(updateArticle.value)
    const stateAttributeIndex = visibleAttributes.value.findIndex(attr => attr.SystemName === editRequestKeys.stateName)
    if (stateAttributeIndex !== -1) {
      // Update the attribute in the visibleAttributes array
      visibleAttributes.value[stateAttributeIndex] = Object.assign({}, visibleAttributes.value[stateAttributeIndex], {
        DropDownData: activeStates,
        DropDownValueProp: 'StateId',
        DropDownValueDisplayProp: 'StateName',
        AttributeType: AttributeType.Int,
      })
    }
  }
  // PrimaryColor and ColorPalatte attributes
  else if (attribute.SystemName === editRequestKeys.colorId || attribute.AttributeType === AttributeType.ColorPalette) {
    const currentValueIndex = visibleAttributes.value.findIndex(attr => attr.SystemName === 'CurrentValue')
    if (currentValueIndex !== -1) {
      // Update the attribute in the visibleAttributes array
      visibleAttributes.value[currentValueIndex] = Object.assign({}, visibleAttributes.value[currentValueIndex], {
        AttributeType: AttributeType.ColorPalette,
        AllowFiltering: true,
        articles: [updateArticle.value],
        IsPrimaryColor: attribute.SystemName === 'ColorId',
        isReadonly: true,
      })
    }
    const colorAttributeIndex = visibleAttributes.value.findIndex(attr => attr.SystemName === attribute.SystemName)
    if (colorAttributeIndex !== -1) {
      // Update the attribute in the visibleAttributes array
      visibleAttributes.value[colorAttributeIndex] = Object.assign({}, visibleAttributes.value[colorAttributeIndex], {
        AttributeType: AttributeType.ColorPalette,
        AllowFiltering: true,
        articles: [updateArticle.value],
        IsPrimaryColor: attribute.SystemName === 'ColorId',
      })
    }
  }
  else if (attribute.SystemName === editRequestKeys.status) {
    // set the default value for requestedAttribute as 0
    form[attribute.SystemName] = 0
  }
  else if (attribute.SystemName === editRequestKeys.sizeScaleId) {
    const sizeScaleIndex = visibleAttributes.value.findIndex(attr => attr.SystemName === editRequestKeys.sizeScaleId)
    if (sizeScaleIndex !== -1) {
      // Create new size scale field
      const newSizeScaleField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
        SystemName: 'SizeScaleId',
        DisplayName: t('general.sizeScale'),
        Creatable: true,
        IsRequired: true,
        AttributeType: AttributeType.SizeScale,
      })

      visibleAttributes.value.splice(sizeScaleIndex, 1, newSizeScaleField)
    }

    const masterSizeScaleExists = visibleAttributes.value.some(attr => attr.SystemName === 'MasterSizeScaleId')
    if (!masterSizeScaleExists) {
      // Create master size scale field
      const masterSizeScaleField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
        SystemName: 'MasterSizeScaleId',
        DisplayName: t('general.masterSizeScale'),
        Creatable: true,
        ReadOnly: true,
        AttributeType: AttributeType.MasterSizeScale,
      })
      // do not allow user to change MasterSizeScale through request
      form[masterSizeScaleField.SystemName] = article[masterSizeScaleField.SystemName]

      visibleAttributes.value.splice(sizeScaleIndex, 0, masterSizeScaleField)
    }
  }
  else if (attribute.SystemName === editRequestKeys.prices) {
    const maxArticleStateRank = getArticlesMaxStateDetails([article], !!userStore.activeCatalog!.IsPriceByStyleApply)
    const pricesAttributes: Array<IMyAttribute> = []
    userStore.activeCatalog!.CatalogPriceGroupList.forEach((priceGroup) => {
      if (priceGroup.Status && maxArticleStateRank) {
        // if pricegroup is non editable or not locked based on article state do not show it in the form
        // only pricesgroups non editable should be allowed to create request
        const priceGroupStateRank = priceGroup.StateRank || 0
        const lockBasedOnArticlesState = !skipRestrictingPropertyUpdateBasedOnArticleState && priceGroup.StateRank != null && maxArticleStateRank.StateRank >= priceGroupStateRank
        // if reginal user allow creating request for all prices groups
        const isFieldsReadonlyForRegionalUser = userStore.userProfile.AccountDetails.AccountTypeId !== 1 && userStore.userProfile.AccountDetails.AccountId !== userStore.activeCatalog!.AccountId

        if (lockBasedOnArticlesState || !priceGroup.IsEditable || isFieldsReadonlyForRegionalUser || isArticlePricesLocked.value) {
          // Create current value field for the price group
          const priceCurrentValueField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
            AttributeType: AttributeType.Decimal,
            DisplayName: `${priceGroup.Description} (${priceGroup.CurrencyCode}) ${t('requests.editRequestDialog.currentValue')}`,
            SystemName: `CurrentValue${priceGroup.Id}`,
            ReadOnly: true,
          })
          const currentPrice = updateArticle.value!._Prices[priceGroup.Id] ? updateArticle.value!._Prices[priceGroup.Id].Price : 0
          form[priceCurrentValueField.SystemName] = currentPrice
          pricesAttributes.push(priceCurrentValueField)

          const priceField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
            AttributeType: AttributeType.Decimal,
            DisplayName: `${priceGroup.Description} ${t('requests.editRequestDialog.requestedValue')}`,
            SystemName: priceGroup.Id,
          })
          pricesAttributes.push(priceField)
        }
      }
    })

    if (pricesAttributes.length > 0) {
      visibleAttributes.value.unshift(...pricesAttributes)
    }
  }

  loading.value = false
}

function doCancel() {
  visible.value = false
  emit('cancel')
}

async function doSave() {
  errorMessage.value = ''
  if (!(await v$.value.$validate())) {
    errorMessage.value = t('validations.formInvalid')
  }

  loading.value = true
  try {
    const payload: CreateRequestModel = {
      ObjectId: guid(),
      InitialContent: '',
      Content: '',
      State: requestConstants.requestStates.new,
      IsCreateArticleRequest: false,
      RequestSource: requestConstants.requestSources.editArticle,
      RequestType: getRequestType(),
      RequestVersion: requestConstants.requestVersion,
      SourceModelId: updateArticle.value!.ModelId,
      SourceModelNumber: updateArticle.value!.ModelNumber,
      SourceArticleId: updateArticle.value!.Id,
      SourceArticleNumber: updateArticle.value!.ArticleNumber,
      InternalDescription: [],
      IsSeasonlessRequest: 0,
      SourceCatalogCode: updateArticle.value!.CatalogCode,
      SourceCatalogName: updateArticle.value!.CatalogCode === userStore.activeCatalog?.CatalogCode ? userStore.activeCatalog?.CatalogName : userStore.linkedCatalogDetails[updateArticle.value!.CatalogCode].CatalogName,
    }

    // add Content and InitialContent
    const content = {}
    const initialContent = {}
    const internalDescription: any = []
    if (updateAttribute.value!.SystemName === editRequestKeys.prices) {
      for (const key in form) {
        payload[key] = form[key]
      }

      content[editRequestKeys.prices] = []
      initialContent[editRequestKeys.prices] = []
      userStore.activeCatalog!.CatalogPriceGroupList.forEach((priceGroup) => {
        if (priceGroup.Status) {
          const currentPriceField = `CurrentValue${priceGroup.Id}`
          const priceGroupId = priceGroup.Id
          if (utils.isDefined(form[priceGroupId])) {
            initialContent[editRequestKeys.prices].push({
              Id: priceGroupId,
              Name: `${priceGroup.Description} (${priceGroup.CurrencyCode})`,
              Price: form[currentPriceField],
            })

            content[editRequestKeys.prices].push({
              Id: priceGroupId,
              Name: `${priceGroup.Description} (${priceGroup.CurrencyCode})`,
              Price: form[priceGroupId],
            })
            internalDescription.push({ Attribute: `${priceGroup.Description} (${priceGroup.CurrencyCode})`, InitialValue: form[currentPriceField], RequestedValue: form[priceGroupId] })
          }
          // delete the price entries from payload
          delete payload[currentPriceField]
          delete payload[priceGroupId]
        }
      })
    }
    else {
      // when saving content we should save the key as SizeScale
      const requestKey = updateAttribute.value!.SystemName === editRequestKeys.sizeScaleId ? editRequestKeys.sizeScale : updateAttribute.value!.SystemName
      content[requestKey] = form[updateAttribute.value!.SystemName]
      initialContent[requestKey] = form.CurrentValue
      // for creating internal description
      const attributeDisplayName = updateAttribute.value!.DisplayName
      let initialValue = utils.getAttributeTypeSpecificValueWithValue(updateAttribute.value!, form.CurrentValue)
      let requestedValue = utils.getAttributeTypeSpecificValueWithValue(updateAttribute.value!, form[updateAttribute.value!.SystemName])
      if (updateAttribute.value!.SystemName === editRequestKeys.colorId || updateAttribute.value?.AttributeType === AttributeType.ColorPalette) {
        content[requestKey] = utils.tryParse(form[requestKey])
        initialContent[requestKey] = utils.tryParse(form.CurrentValue) ?? form.CurrentValue
        requestedValue = Object.values(content[requestKey])[0]
        if (form.CurrentValue) {
          if (typeof form.CurrentValue === 'object') {
            initialValue = Object.values(form.CurrentValue)[0]
          }
          else if (typeof form.CurrentValue === 'string') {
            const parsedValue = utils.tryParse(form.CurrentValue)
            initialValue = parsedValue && typeof parsedValue === 'object'
              ? Object.values(parsedValue)[0]
              : form.CurrentValue
          }
        }
      }
      else if (updateAttribute.value!.AttributeType === AttributeType.MultiValue) {
        let newValue = form[requestKey]
        if (!Array.isArray(newValue)) {
          newValue = newValue.split(/\r?\n/).reduce((acu, cur) => {
            if (cur != null && cur.toString().trim() !== '') {
              acu.push(cur)
            }
            return acu
          }, [])
        }
        content[requestKey] = newValue
        requestedValue = newValue.join('|')
      }
      else if (updateAttribute.value!.SystemName === editRequestKeys.stateName) {
        const state = userStore.articleStateList.find(state => state.StateId === form[updateAttribute.value!.SystemName])
        if (state) {
          requestedValue = state.StateName
        }
      }
      else if (updateAttribute.value!.SystemName === editRequestKeys.sizeScaleId) {
        initialContent[requestKey] = userStore.sizeScales[form.CurrentValue].SizeScale
        initialValue = userStore.sizeScales[form.CurrentValue].SizeScale
        const sizeScaleObj = userStore.sizeScales[form[updateAttribute.value!.SystemName]]
        if (sizeScaleObj) {
          requestedValue = sizeScaleObj.SizeScale
        }
      }
      else {
        content[requestKey] = utils.getArticleAttributeTypeSpecificValue(updateAttribute.value!, form[updateAttribute.value!.SystemName])
      }

      internalDescription.push({ Attribute: attributeDisplayName, InitialValue: initialValue || t('general.blank'), RequestedValue: requestedValue })

      // request attributes
      for (const key in form) {
        if (key !== 'CurrentValue' && key !== 'MasterSizeScaleId' && key !== updateAttribute.value!.SystemName) {
          payload[key] = form[key]
        }
      }
    }

    payload.Content = JSON.stringify(content)
    payload.InitialContent = JSON.stringify(initialContent)
    payload.InternalDescription = JSON.stringify(internalDescription)

    // include the calc request attribute
    if (userStore.activeCatalog && userStore.myAttributes) {
      for (const requestAttribute of userStore.activeCatalog.RequestAttributeList) {
        const attribute = userStore.myAttributes[requestAttribute.AttributeSystemName]
        const value = await utils.getCalcRequestAttributeValue(userStore.activeCatalog, attribute, payload, content, appConfig.DB, form, userStore.priceGroups)
        if (utils.isDefined(value)) {
          payload[attribute.SystemName] = value
        }
      }
    }

    if (userStore.activeCatalog) {
      await createRequestBulk(userStore.activeCatalog.CatalogCode, [payload])
        .then(async () => {
          await userStore.doLoadData(['Requests'])
          emit('editRequestCreated')
        })
        .catch ((error) => {
          console.error(error)
        })
    }
  }
  finally {
    loading.value = false
    visible.value = false
  }
}

function reset() {
  loading.value = false
  visible.value = true
  updateAttribute.value = null
  updateArticle.value = null
  Object.keys(form).forEach((key) => {
    delete form[key] // Remove all form properties
  })
  v$.value.$reset()
  showAffectedSeasons.value = false
  errorMessage.value = ''
}

function getActiveStates(article: MyArticle) {
  // Filter only allowed states which are higher than the selected article's current state
  const activeStates = userStore.articleStateList.filter(state => state.Status)
  const maxArticleStateRank = getArticlesMaxStateDetails([article], false)

  let validStates: Record<string, any>[] = []
  if (maxArticleStateRank) {
    validStates = activeStates.filter(articleState => articleState.StateRank > maxArticleStateRank.StateRank)
  }
  return validStates
}

defineExpose({
  showDialog,
})

function getRequestType() {
  if (!updateAttribute.value!.IsStatic) {
    return requestConstants.requestTypes.EditAttribute.key
  }
  else {
    if (updateAttribute.value!.SystemName === editRequestKeys.status) {
      return requestConstants.requestTypes.EditStatus.key
    }
    else if (updateAttribute.value!.SystemName === editRequestKeys.stateName) {
      return requestConstants.requestTypes.EditArticleState.key
    }
    else if (updateAttribute.value!.SystemName === editRequestKeys.sizeScaleId) {
      return requestConstants.requestTypes.EditSizeScale.key
    }
    else if (updateAttribute.value!.SystemName === editRequestKeys.modelName) {
      return requestConstants.requestTypes.EditModelName.key
    }
    else if (updateAttribute.value!.SystemName === editRequestKeys.colorId) {
      return requestConstants.requestTypes.EditPrimaryColor.key
    }
    else if (updateAttribute.value!.SystemName === editRequestKeys.prices) {
      return requestConstants.requestTypes.EditPrices.key
    }
  }
  return requestConstants.requestTypes.EditAttribute.key
}

function handleAttachmentsUploadingStatus(status: boolean) { // once files uploaded and if common value enabled set the guid to all rows
  isRequestAttachmentsUploading.value = status
}
</script>
