import { clone, cloneDeep } from 'lodash-es'
import type { IField, IImportModel } from '@/modules/admin/type'
import type { ITxDataTableColumn, ITxDataTableVisibleColumn } from '@/shared/components/txDataTable/TxDataTable.types'
import { AttributeType } from '@/models/catalogAttribute'
import { useUserStore } from '@/store/userData'
import { requestConstants } from '@/models/constants'

export default class SeasonlessRequestsModel {
  modelPath: string
  fields: IField[]
  importInfo: Record<string, any>

  constructor(t) {
    this.modelPath = 'catalogs.seasonlessRequestsTable'
    this.importInfo = {
      isCatalogSpecificModel: true,
      uniqueConstraints: [
        { name: 'Constraint 1', fields: ['Id'] },
      ],
    }
    // NOTE: Always use the clone of fields never change the property directly on field
    this.fields = this.getFields(t)
  }

  private getFields(t) {
    const stateFilterLookUp = new Map([
      [requestConstants.requestStates.new, t('filter.new')],
      [requestConstants.requestStates.approve, t('requests.approved')],
      [requestConstants.requestStates.reject, t('requests.rejected')],
      [requestConstants.requestStates.draft, t('filter.draft')],
      [requestConstants.requestStates.confirm, t('requests.confirmed')],
    ])
    const statusFilterLookUp = new Map([
      [-1, t('general.all')],
      [1, t('general.active')],
      [0, t('general.inactive')],
    ])
    const requestTypeLookUp = new Map(
      Object.values(requestConstants.requestTypes).map(({ key, label }) => [key.toLowerCase(), t(label)]),
    )
    // const { getArticlesLookup } = useMasterDataLookup()
    const fields: IField[] = [
      { SystemName: 'Id', DisplayName: t('requestsTable.fields.requestId'), Visible: true, AttributeType: AttributeType.Int } as IField,
      { SystemName: 'ObjectId', DisplayName: t('requestsTable.fields.objectId'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: '_SourceArticleNumber', DisplayName: t('requestsTable.fields.sourceArticleNumber'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: '_RequestSourceModelNumber', DisplayName: t('requestsTable.fields.sourceModelNumber'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: 'StateName', DisplayName: t('requestsTable.fields.stateName'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: '_impactedAttribute', DisplayName: t('requestsTable.fields.impactedAttribute'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: '_initialValue', DisplayName: t('requestsTable.fields.currentValue'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: '_requestedValue', DisplayName: t('requestsTable.fields.requestedValue'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: 'RequestVersion', DisplayName: t('requestsTable.fields.requestVersion'), Visible: true, AttributeType: AttributeType.Int } as IField,
      { SystemName: 'Status', DisplayName: t('requestsTable.fields.status'), Visible: true, AttributeType: AttributeType.Status, FilterLookup: statusFilterLookUp } as IField,
      { SystemName: 'State', DisplayName: t('requestsTable.fields.requestState'), Visible: true, AttributeType: AttributeType.Int, FilterLookup: stateFilterLookUp, SupportFilteringByMultipleValues: false, TriggerChangeEvent: true } as IField,
      { SystemName: 'Reason', DisplayName: t('requestsTable.fields.reason'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: 'Comment', DisplayName: t('requestsTable.fields.comment'), Visible: true, showInEdit: false, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: 'RequestType', DisplayName: t('requestsTable.fields.requestType'), Visible: true, AttributeType: AttributeType.Nvarchar, FilterLookup: requestTypeLookUp } as IField,
      { SystemName: '_ImpactedSeasons', DisplayName: t('requestsTable.fields.impactedSeasons'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: 'HasAttachment', DisplayName: t('requestsTable.fields.hasAttachments'), Visible: true, AttributeType: AttributeType.Bool } as IField,
      { SystemName: 'CreatedByUserName', DisplayName: t('requestsTable.fields.createdByUserName'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: 'UpdatedByUserName', DisplayName: t('requestsTable.fields.updatedByUserName'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: 'CreatedDate', DisplayName: t('requestsTable.fields.createdDate'), Visible: true, AttributeType: AttributeType.Date } as IField,
      { SystemName: 'UpdatedDate', DisplayName: t('requestsTable.fields.updatedDate'), Visible: true, AttributeType: AttributeType.Date } as IField,
      { SystemName: 'CatalogCode', DisplayName: t('requestsTable.fields.catalogCode'), Visible: true, AttributeType: AttributeType.Int } as IField,
      { SystemName: 'CatalogName', DisplayName: t('requestsTable.fields.catalogName'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: 'SourceCatalogCode', DisplayName: t('requestsTable.fields.sourceCatalogCode'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
      { SystemName: 'SourceCatalogName', DisplayName: t('requestsTable.fields.sourceCatalogName'), Visible: true, AttributeType: AttributeType.Nvarchar } as IField,
    ]
    return fields
  }

  getDataTableColumns() {
    const dataTableFields: ITxDataTableColumn[] = this.fields.filter(field => field.Visible)
      .map(o => ({
        property: o.SystemName,
        title: o.DisplayName,
        filterLookup: o.FilterLookup,
        type: o.AttributeType,
        isRequest: o.IsRequest,
        lookupDisplayField: o.lookupDisplayField,
        lookupSource: o.lookupSource,
        lookupIdField: o.lookupIdField,
        supportFilteringByMultipleValues: o.SupportFilteringByMultipleValues,
        triggerChangeEvent: o.TriggerChangeEvent,
      }))
    return dataTableFields
  }

  getDataDetailsColumns() {
    const userStore = useUserStore()
    if (userStore.activeCatalog && userStore.activeCatalog.RequestAttributeList.length) {
      const requestAttributes = userStore.activeCatalog.RequestAttributeList.filter(a => a.Status > 0)
      requestAttributes.forEach((attribute) => {
        const newAtt: IField = {
          SystemName: attribute.AttributeSystemName,
          DisplayName: attribute.AttributeDisplayName,
          AttributeType: attribute.AttributeTypeId,
          Visible: true,
          Creatable: false,
          Editable: false,
          IsRequired: false,
          IsStatic: false,
          IsRequest: true,
          IsPersonal: false,
        }
        this.fields.push(newAtt)
      })
    }
    const dataTableFields: ITxDataTableColumn[] = this.fields.filter(field => field.Visible)
      .map(o => ({
        property: o.SystemName,
        title: o.DisplayName,
        filterLookup: o.FilterLookup,
        type: o.AttributeType,
        isRequest: o.IsRequest,
        lookupDisplayField: o.lookupDisplayField,
        lookupSource: o.lookupSource,
        lookupIdField: o.lookupIdField,
        supportFilteringByMultipleValues: o.SupportFilteringByMultipleValues,
        triggerChangeEvent: o.TriggerChangeEvent,
      }))
    return dataTableFields
  }

  getDefaultVisibleColumnList() {
    // Default column list
    const defaultColumns = ['Id', 'CatalogName', 'SourceArticleId', 'RequestType', 'State', '_impactedAttribute', '_initialValue', '_requestedValue', 'Status', 'UpdatedByUserName', 'UpdatedDate']

    const defaultFieldObjects: ITxDataTableVisibleColumn[] = this.fields.filter(field =>
      defaultColumns.includes(field.SystemName) && field.Visible && !field.IsPersonal,
    ).map(o => ({
      property: o.SystemName,
      order: 1,
      width: 0.175,
    }))

    return defaultFieldObjects
  }

  getImportData(action: string) {
    const importFields: IField[] = []
    const importInfo = cloneDeep(this.importInfo)
    if (action === 'editRequestBulk') {
      for (let i = 0; i < this.fields.length; i++) {
        const field = clone(this.fields[i])
        if (field.SystemName === 'Id') {
          field.SystemName = 'RequestId'
          field.IsRequired = true
          importFields.push(field)
        }
        if (field.IsRequest && field.AttributeType !== AttributeType.Calc) {
          importFields.push(field)
        }
      }
    }
    return { importInfo, modelPath: this.modelPath, fields: importFields } as IImportModel
  }
}
