import { fabric } from 'fabric'
import { isArray } from 'lodash-es'
import type { ShallowRef } from 'vue'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import type WbConnector from '../services/connector'
import WbFrame from '../services/frame'
import utils from '@/services/utils'
import WbImage from '@/modules/whiteboard/services/image'
import WbTextBox from '@/modules/whiteboard/services/textBox'
import type Whiteboard from '@/modules/whiteboard/services/whiteboard'
import { imageConstants, whiteboardConstants } from '@/models/constants'
import { upload } from '@/api/whiteboard/file'
import { useEventListener } from '@/shared/composables/event'

export default function useWindowEvents(whiteboard: ShallowRef<Whiteboard | undefined>, whiteboardId: ShallowRef<number>) {
  let wb: Whiteboard
  const textEditingDialogVisible = ref(false)
  const findReplaceDialogVisible = ref(false)
  const generateFrameDialogVisible = ref(false)
  const isFullScreen = ref(false)
  const isArticleOrModelDetailsVisible = ref(false)
  const isDisableCopyPasteUndoRedo = ref(false)
  const uploadImagesProgressTitle = ref('')
  const uploadImageProgress = ref(0)
  const uploadImageProgressDialogVisible = ref(false)
  let selectedFrameIndex = 0
  const { t } = useI18n()
  watch(whiteboard, () => {
    if (whiteboard.value) {
      init()
    }
  }, { immediate: true })

  watch(() => isFullScreen.value, (val) => {
    if (val) {
      selectedFrameIndex = 0
      if (wb.frames.value.length > 0) {
        wb.centerObject(wb.frames.value[selectedFrameIndex])
      }
    }
  })

  useEventListener(window, 'keydown', handleKeyDown)

  // keep this event since navigator.clipboard have compatibilities issues (read method is not supported in firefox and partially supported by chromium based browsers)
  useEventListener(window, 'paste', handlePaste)

  function init() {
    wb = whiteboard.value!
  }

  function handleKeyDown(event: KeyboardEvent) {
    const disabledKeyEvent = event.target !== document.body || isFullScreen.value
    if (wb && !disabledKeyEvent) {
      switch (event.key) {
        case 'Delete':
        case 'Backspace': {
          const objs = wb.canvas.getActiveObjects() as Array<IWbObject>
          wb.removeObjects(objs, true)
          break
        }
        case 'Escape': {
          wb.canvas.discardActiveObject()
          wb.canvas.requestRenderAll()
          break
        }
        case 'A':
        case 'a':
          if (event.metaKey || event.ctrlKey) {
            wb.selectAllObjects()
          }
          break
        case 'C':
        case 'c':
          if ((event.metaKey || event.ctrlKey) && !isArticleOrModelDetailsVisible.value && !isDisableCopyPasteUndoRedo.value) {
            wb.copySelectedObjects()
          }
          break
        // case 'V':
        // case 'v':
        //   if(event.metaKey || event.ctrlKey) {
        //     _debounce(() => wb.pasteClipboardObjects(), 0)()
        //   }
        //   break
        case 'F':
        case 'f':
          if (event.metaKey || event.ctrlKey) {
            findReplaceDialogVisible.value = true
            event.preventDefault()
          }
          break
        case 'Z':
        case 'z':
          if (!isDisableCopyPasteUndoRedo.value) {
            if (event.metaKey || event.ctrlKey) {
              if (event.shiftKey) {
                wb.redo()
              }
              else {
                wb.undo()
              }
            }
          }
          break
        case 'PageUp': {
          const objs = wb.canvas.getActiveObjects()
          wb.bringObjectsToFront(objs)
          break
        }
        case 'PageDown': {
          const objs = wb.canvas.getActiveObjects()
          wb.sendObjectsToBack(objs)
          break
        }
        case 'ArrowUp' /* Up arrow */:
          wb.moveObject(event, 'up')
          break
        case 'ArrowDown' /* Down Arrow */:
          wb.moveObject(event, 'down')
          break
        case 'ArrowLeft' /* Left Arrow */:
          wb.moveObject(event, 'left')
          break
        case 'ArrowRight' /* Right Arrow */:
          wb.moveObject(event, 'right')
          break
        case 'Q':
        case 'q':
          if ((event.metaKey || event.ctrlKey) && event.shiftKey) {
            wb.toogleDebugData()
          }
      }
      if (event.key && event.key.toString().toLowerCase() !== 'v') {
        event.preventDefault()
      }
    }
    // for full screen
    if (wb && isFullScreen.value) {
      switch (event.key) {
        // next
        case 'ArrowDown':
        case 'ArrowRight':
        case 'PageDown':
          if (selectedFrameIndex < wb.frames.value.length) {
            selectedFrameIndex++
            wb.centerObject(wb.frames.value[selectedFrameIndex])
          }
          break

        // back
        case 'ArrowUp':
        case 'ArrowLeft':
        case 'PageUp':
          if (selectedFrameIndex > 0) {
            selectedFrameIndex--
            wb.centerObject(wb.frames.value[selectedFrameIndex])
          }
          break
      }
      event.preventDefault()
    }
  }
  async function handleImagesDrop(files: File[], pointer: { x: number, y: number }) {
    uploadImageProgressDialogVisible.value = true
    let xOffset = pointer.x
    let yOffset = pointer.y
    let maxHeight = 0
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (i !== 0 && i % 10 === 0) {
        yOffset += maxHeight + 15
        xOffset = pointer.x
      }
      await upload(whiteboardId.toString(), file)
        .then(async (res) => {
          if (res.data && res.data.url) {
            const options: fabric.IImageOptions = {}
            if (pointer) {
              options.top = yOffset
              options.left = xOffset
            }
            const img = await WbImage.loadFromUrl(res.data.url, options)
            if (img && img.width && img.height) {
              const max_size = 250
              let scaleX = 100
              let scaleY = 100
              const scale = 100
              if (img.width > img.height) {
                if (img.width > max_size) {
                  const height = img.height * (max_size / img.width)
                  scaleY = (height / img.height) * 100
                  scaleX = (max_size / img.width) * 100
                }
              }
              else {
                if (img.height > max_size) {
                  const width = img.width * (max_size / img.height)
                  scaleX = (width / img.width) * 100
                  scaleY = (max_size / img.height) * 100
                }
              }
              img.setProp('scale', { scaleX, scaleY, scale })
              maxHeight = Math.max((img.height * (scaleY / 100)), maxHeight)
              xOffset += (img.width * (scaleX / 100)) + 10
            }
            wb.addObjects([img], true)
          }
          else {
            console.warn('Failed to upload image')
          }
        }).catch((err) => {
          console.warn('Failed to upload image', err)
        }).finally(() => {
          uploadImageProgress.value = Math.round(((i + 1) / files.length) * 100)
          uploadImagesProgressTitle.value = t('whiteboard.uploadImage.updatedProgressTitle') + (files.length ? ` (${i + 1} / ${files.length})` : '')
        })
    }
  }

  function closeUploadImageDialog() {
    uploadImageProgressDialogVisible.value = false
    uploadImageProgress.value = 0
  }
  async function handlePaste(event: Event | ClipboardEvent) {
    const disabledPasting = event.target !== document.body || isFullScreen.value || isDisableCopyPasteUndoRedo.value
    if (wb && !disabledPasting && event instanceof ClipboardEvent && event.clipboardData) {
      const viewPortBoundaries = wb.canvas.calcViewportBoundaries()
      const left = viewPortBoundaries.tl.x + 100
      const top = viewPortBoundaries.tl.y + 100

      function getData(dataItem: DataTransferItem) {
        return new Promise<string>((resolve) => {
          dataItem.getAsString((data) => {
            resolve(data)
          })
        })
      }

      console.log('paste', event.clipboardData.items)

      // Index the types of all clipboard items
      const types = Array.from(event.clipboardData.items).map(item => item.type)

      // First check if we have our own mime type
      let newObjs: IWbObject[] = []
      const wbObjectsIndex = types.indexOf('text/html')
      const firstImgIndex = types.findIndex(type => imageConstants.validImageFormats.has(type))
      const firstTextIndex = types.findIndex(type => type === 'text/plain')

      if (wbObjectsIndex !== -1) {
        const data = await getData(event.clipboardData.items[wbObjectsIndex])
        const wbDataStart = data.indexOf('(t1-data-v1)') + 12
        const wbDataEnd = data.indexOf('(/t1-data-v1)')
        if (wbDataStart > 12 && wbDataEnd > wbDataStart) {
          const copiedObjects = utils.tryParse(decodeURI(data.substring(wbDataStart, wbDataEnd)))
          if (copiedObjects && Array.isArray(copiedObjects) && copiedObjects.length > 0) {
            const offsetX = ((viewPortBoundaries.tl.x + viewPortBoundaries.tr.x) / 2)
            const offsetY = (viewPortBoundaries.tl.y + 100)
            let newObjects: any[] = []
            copiedObjects.forEach((objData) => {
              const copiedObject = objData.object
              copiedObject.left = offsetX + objData.relativePosition.left
              copiedObject.top = offsetY + objData.relativePosition.top
              const resetId = function (o) {
                if (o.hasOwnProperty('id')) {
                // oldId will be used for connectors to connect to newly created start and end objects after pasting the objects in whiteboard
                  o.oldId = o.id
                  delete o.id
                }
                if (o.hasOwnProperty('objects') && isArray(o.objects)) {
                  o.objects.forEach(object => resetId(object))
                }
              }
              resetId(copiedObject)
              if (copiedObject.type === whiteboardConstants.objectTypes.frame) {
                copiedObject.children = []
              }
              newObjects.push(copiedObject)
            })

            const indexedObjects = utils.arrayToStringDictionary(newObjects, 'oldId')
            // prevent pasting discussion objects or connectors that has only one of its connected object selected
            newObjects = newObjects.filter(obj => obj.type !== whiteboardConstants.objectTypes.discussion
            && (obj.type !== whiteboardConstants.objectTypes.connector
            || (indexedObjects.hasOwnProperty((obj as WbConnector).startObjectId) && indexedObjects.hasOwnProperty((obj as WbConnector).endObjectId!))
            ),
            )

            if (newObjects.length) {
              function addObjects(newObjects: any[]) {
                return new Promise<IWbObject[]>((resolve) => {
                  fabric.util.enlivenObjects(newObjects, (objs) => {
                    wb.manageEnlivenConnectors(objs, true)
                    resolve(objs)
                  }, '')
                })
              }
              newObjs = await addObjects(newObjects)
            }
          }
        }
      }
      else if (firstImgIndex !== -1) {
        const items = Array.from(event.clipboardData.items).filter(item => item.kind === 'file' && imageConstants.validImageFormats.has(item.type))
        const files: File[] = []
        items.forEach((item) => {
          const file = item.getAsFile()
          if (file && file.size <= imageConstants.maxImageSize) {
            files.push(file)
          }
        })
        handleImagesDrop(files, { x: left, y: top })
      }
      else if (firstTextIndex !== -1) {
        const data = await getData(event.clipboardData.items[firstTextIndex])
        if (utils.isDefined(data) && utils.isValidStringValue(data)) {
          newObjs.push(new WbTextBox(data, { left, top, fontSize: 20 }))
        }
      }

      if (newObjs.length && firstImgIndex === -1) {
        wb.canvas.discardActiveObject()
        wb.addObjects(newObjs, true)
        // handle frame and children
        newObjs.forEach((obj) => {
          if (obj.type === whiteboardConstants.objectTypes.frame && obj instanceof WbFrame) {
            obj.addOrRemoveChildren()
          }
        })
        const selection = new fabric.ActiveSelection(newObjs, { canvas: wb.canvas })
        wb.canvas.setActiveObject(selection)
        wb.canvas.requestRenderAll()
      }
    }
  }

  return {
    textEditingDialogVisible,
    findReplaceDialogVisible,
    generateFrameDialogVisible,
    isFullScreen,
    isArticleOrModelDetailsVisible,
    isDisableCopyPasteUndoRedo,
    uploadImageProgressDialogVisible,
    handleImagesDrop,
    closeUploadImageDialog,
    uploadImageProgress,
    uploadImagesProgressTitle,
  }
}
