<template>
  <tx-dialog
    v-model="visible" :title="actionType === requestConstants.requestStates.approve ? t('requests.approveRequest') : t('requests.rejectRequest')"
    show-ok-cancel :ok-state="v$.$invalid ? 'disabled' : loading ? 'loading' : 'enabled'" :confirm-text="actionType === requestConstants.requestStates.approve ? 'requests.approve' : 'requests.reject'"
    @click="doCancel" @ok="onApproveReject"
  >
    <div class="w-full h-full">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
      <!-- FORM -->
      <div class="w-full h-full">
        <div v-for="attribute in computedVisibleAttributes" :key="attribute.SystemName" class="mb-4">
          <attribute-editor
            v-model="formModel[attribute.SystemName]"
            :form="formModel"
            :articles="[]"
            :attribute="attribute"
            :disabled="attribute.ReadOnly"
            :required="attribute.IsRequired"
          />
        </div>
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { computed, reactive, ref } from 'vue'
import { createI18nMessage, required } from '@vuelidate/validators'
import { approveRequests, getRequestReasons, rejectRequests } from '@/api/t1/request'
import { useUserStore } from '@/store/userData'
import { appConstants, requestConstants } from '@/models/constants'
import { AttributeType } from '@/models/catalogAttribute'
import type { RequestModel, RequestReasonsModel, UpdateRequestStateModel } from '@/api/t1/model/requestModel'
import TxAlert from '@/shared/components/TxAlert.vue'
import AttributeEditor from '@/shared/components/AttributeEditor.vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import useErrorMessage from '@/shared/composables/errorMessage'
import utils from '@/services/utils'
import { updateSeasonlessRequestsState } from '@/api/t1/seasonlessRequests'
import appConfig from '@/services/appConfig'

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'approveReject', requestIds: number[], propertiesToUpdate: { state: number, comment: string, reason: string, updatedByUserName: string }): void
}>()

const { t } = useI18n()
const withI18nMessage = createI18nMessage({ t })
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()

const loading = ref<boolean>(false)
const visible = ref<boolean>(false)
const requestReasons = ref<RequestReasonsModel[]>([])
const formModel = reactive<Record<string, any>>({})
const actionType = ref(0)
let selectedRequests: RequestModel[] = []
const requestReasonsMap = ref<Record<string, RequestReasonsModel[]>>({})
const uniqueCatalogCodes = ref<number[]>([])
const isSeasonlessRequestModel = ref(false)
const visibleAttributes = ref<IMyAttribute[]>([])

const computedVisibleAttributes = computed(() => visibleAttributes.value)
const rules = computed(() => {
  const rules: Record<string, any> = {}
  visibleAttributes.value.forEach((visibleAttribute) => {
    if (visibleAttribute.IsRequired) {
      rules[visibleAttribute.SystemName] = { required: withI18nMessage(required) }
    }
  })
  return rules
})

const v$ = useVuelidate(rules, formModel)

async function showDialog(selectedReqs, type: number, isSeasonlessRequest?: boolean) {
  reset()
  actionType.value = type
  const reasonType = type === requestConstants.requestStates.approve ? 1 : 2
  selectedRequests = selectedReqs
  isSeasonlessRequestModel.value = !!(utils.isDefined(isSeasonlessRequest) && isSeasonlessRequest)

  uniqueCatalogCodes.value = [...new Set(selectedRequests.map(req => req.CatalogCode))]

  if (uniqueCatalogCodes.value.length) {
    loading.value = true
    try {
      const requests = uniqueCatalogCodes.value.map(async (catalogCode) => {
        const res = await getRequestReasons(catalogCode)

        const currentRequest = selectedRequests.find(req => req.CatalogCode === catalogCode)
        let requestType = ''

        if (currentRequest) {
          // Assign requestType based on the specific request for the catalogCode
          if (utils.isDefined(requestConstants.requestTypes[currentRequest.RequestType])) {
            requestType = requestConstants.requestTypes[currentRequest.RequestType].key
          }
          else if (currentRequest.RequestSource === requestConstants.requestSources.similarStyle) {
            requestType = 'SimilarStyle'
          }
          else {
            requestType = 'AddColorway'
          }

          if (currentRequest.RequestType === requestConstants.requestTypes.EditAttribute.key) {
            requestType = Object.keys(currentRequest.Content)[0]
          }
        }

        return {
          catalogCode,
          reasons: res.data.filter(reason => reason.Status === 1 && reason.Type === reasonType && reason.RequestType === requestType),
        }
      })

      const results = await Promise.all(requests)

      requestReasonsMap.value = results.reduce((acc, { catalogCode, reasons }) => {
        acc[catalogCode] = reasons
        return acc
      }, {} as Record<number, RequestReasonsModel[]>)
      loadVisibleAttributes()
    }
    catch (e) {
      console.error('Error fetching request reasons:', e)
    }
    finally {
      loading.value = false
    }
  }
}

async function loadVisibleAttributes() {
  const attributes: IMyAttribute[] = []

  // Add comment field
  const commentField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
    DisplayName: t('requests.comments'),
    IsRequired: actionType.value !== 2,
    AttributeType: AttributeType.Nvarchar,
    SystemName: 'Comment',
    ReadOnly: false,
  })
  attributes.push(commentField)

  if (uniqueCatalogCodes.value.length === 1) {
    // Single catalog
    const catalogCode = uniqueCatalogCodes.value[0]
    if (requestReasonsMap.value[catalogCode]?.length) {
      const requestReasonField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
        SystemName: 'ReasonId',
        DisplayName: t('requests.reasons'),
        Creatable: true,
        ReadOnly: false,
        IsRequired: true,
        DropDownData: requestReasonsMap.value[catalogCode],
        DropDownValueDisplayProp: 'Reason',
        DropDownValueProp: 'Reason',
      })

      attributes.push(requestReasonField)
    }
  }
  else {
    // Multiple catalogs
    for (const catalogCode of uniqueCatalogCodes.value) {
      if (requestReasonsMap.value[catalogCode]?.length) {
        const catalogObject = await appConfig.DB!.getCatalog(catalogCode)

        const requestReasonField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
          SystemName: `ReasonId_${catalogCode}`,
          DisplayName: `${t('requests.reasons')} (${catalogObject?.CatalogName})`,
          Creatable: true,
          ReadOnly: false,
          IsRequired: true,
          DropDownData: requestReasonsMap.value[catalogCode],
          DropDownValueDisplayProp: 'Reason',
          DropDownValueProp: 'Reason',
        })

        attributes.push(requestReasonField)
      }
    }
  }

  visibleAttributes.value = attributes
}

async function onApproveReject() {
  try {
    errorMessage.value = ''
    loading.value = true

    const processedIds = new Set()
    const requestObjectArray: UpdateRequestStateModel[] = []
    const requestIdsArray: number[] = []

    selectedRequests.forEach((selectedRequest) => {
      if (!processedIds.has(selectedRequest.Id)) {
        let reason = ''
        if (uniqueCatalogCodes.value.length === 1) {
          reason = formModel.ReasonId || ''
        }
        else {
          reason = formModel[`ReasonId_${selectedRequest.CatalogCode}`] || ''
        }

        const requestObject: UpdateRequestStateModel = {
          Id: selectedRequest.Id,
          Reason: reason,
          Comment: formModel.Comment || '',
        }
        if (isSeasonlessRequestModel.value) { // add State
          requestObject.State = actionType.value
        }
        requestObjectArray.push(requestObject)

        processedIds.add(selectedRequest.Id)
        requestIdsArray.push(selectedRequest.Id)
      }
    })

    if (actionType.value === requestConstants.requestStates.approve) {
      await (!isSeasonlessRequestModel.value ? approveRequests(uniqueCatalogCodes.value[0], requestObjectArray) : updateSeasonlessRequestsState(requestObjectArray))
    }
    else {
      await (!isSeasonlessRequestModel.value ? rejectRequests(uniqueCatalogCodes.value[0], requestObjectArray) : updateSeasonlessRequestsState(requestObjectArray))
    }

    // TO DO CHECK THIS IMPLEMENTATION
    emit('approveReject', requestIdsArray, {
      state: actionType.value,
      comment: formModel.Comment || '',
      reason: formModel.ReasonId || '',
      updatedByUserName: userStore.currentUsername,
    })

    visible.value = false
  }
  catch (error: any) {
    errorMessage.value = error.response?.data?.map(e => e.ErrorMessage).join(', ') || t('general.unexpectedError')
    console.error(error)
  }
  finally {
    loading.value = false
  }
}

function doCancel() {
  visible.value = false
  emit('cancel')
}

function reset() {
  requestReasons.value = []
  utils.resetReactiveObject(formModel)
  loading.value = false
  visible.value = true
  errorMessage.value = ''
}

defineExpose({
  showDialog,
  closeDialog: doCancel,
})
</script>
