<template>
  <tr
    class="cursor-pointer border-b last:border-0 hover:bg-gray-100"
    :class="{ 'bg-gray-50': !isFolder }"
    @click="doClick(item?.Id)"
    @click.right.stop="doContext($event, item, folder)"
  >
    <td v-if="isFolder && folder" colspan="5" class="p-2" :style="{ paddingLeft }">
      <font-awesome-icon icon="fa-light fa-folder" />
      <span class="ml-1 font-bold">{{ folder.Name }}</span>
    </td>
    <template v-else-if="item">
      <td class="px-4 py-2" :style="{ paddingLeft }">
        <font-awesome-icon icon="fa-light fa-chalkboard" />
        <span class="ml-1">{{ item.Name }}</span>
      </td>
      <td class="px-4 py-2">
        {{ item.CreatedByName }}
      </td>
      <td class="px-4 py-2">
        {{ item.CreatedByEmail }}
      </td>
      <td class="px-4 py-2">
        <div v-tooltip="{ text: utils.formatDateTime(item.CreatedDate), theme: { placement: 'left' } }">
          <use-time-ago v-slot="{ timeAgo }" :time="new Date(item.CreatedDate)">
            {{ timeAgo }}
          </use-time-ago>
        </div>
      </td>
      <td class="px-4 py-2">
        <div v-tooltip="{ text: utils.formatDateTime(item.UpdatedDate), theme: { placement: 'left' } }">
          <use-time-ago v-slot="{ timeAgo }" :time="new Date(item.UpdatedDate)">
            {{ timeAgo }}
          </use-time-ago>
        </div>
      </td>
    </template>

    <!-- Actions -->
    <td class="px-4 m-auto" @click.stop>
      <tx-button type="icon" faicon="fa-light fa-ellipsis-vertical" @click="doContext($event, item, folder)" />
    </td>
  </tr>

  <!-- Render Children -->
  <template v-if="isFolder && folder">
    <!-- Render Sub-folders -->
    <whiteboard-folder-row
      v-for="childFolder in folder.Children"
      :key="`folder-${childFolder.Id}`"
      :folder="childFolder"
      :depth="depth + 1"
      @click="doClick"
      @context="doContext"
    />

    <!-- Render Items in Folder -->
    <whiteboard-folder-row
      v-for="childItem in folder.Items"
      :key="`item-${childItem.Id}`"
      :item="childItem"
      :depth="depth + 1"
      @click="doClick"
      @context="doContext"
    />
  </template>
</template>

<script setup lang="ts">
import { UseTimeAgo } from '@vueuse/components'
import { computed } from 'vue'
import type { Folder } from '../Whiteboard.types'
import TxButton from '@/shared/components/TxButton.vue'
import utils from '@/services/utils'
import type Whiteboard from '@/models/whiteboard'

const props = defineProps<{
  folder?: Folder
  item?: Whiteboard
  depth?: number
}>()

const emits = defineEmits<{
  (e: 'click', whiteboardId: number): void
  (e: 'context', evt: MouseEvent, item?: Whiteboard, folder?: Folder): void
}>()

const isFolder = computed(() => Boolean(props.folder))
const depth = computed(() => props.depth ?? 0)
const paddingLeft = computed(() => ((depth.value > 0) ? `${Number(depth.value)}rem` : undefined))

function doClick(itemId?: number) {
  if (itemId) {
    emits('click', itemId)
  }
}

function doContext(evt: MouseEvent, item?: Whiteboard, folder?: Folder) {
  emits('context', evt, item, folder)
}
</script>
