import type { fabric } from 'fabric'

export class DebugData {
  canvas: fabric.Canvas
  ctx: CanvasRenderingContext2D
  enabled: boolean

  constructor(canvas: fabric.Canvas) {
    this.canvas = canvas
    this.ctx = canvas.getSelectionContext()
    this.enabled = false
  }

  public enable(e: boolean) {
    this.enabled = e
  }

  public watchRender() {
    this.canvas.on('after:render', () => {
      if (!this.enabled) { return }
      this.drawDebugData()
    })
  }

  private drawDebugData() {
    const obj = this.canvas.getActiveObject() as IWbObject
    if (!obj || !obj.id) { return }
    const s = `id: ${obj.id}\ntype: ${obj.type}\nx,y: ${obj.left},${obj.top}\nw,h: ${obj.width},${obj.height}\nangle: ${obj.angle}\nscaleX,scaleY: ${obj.scaleX},${obj.scaleY}`

    this.ctx.save()
    this.ctx.font = '15px Arial'
    this.ctx.fillStyle = 'red'
    this.ctx.strokeStyle = 'white'
    this.ctx.lineWidth = 2
    const coord = obj.oCoords || { tl: { x: 0, y: 0 }, br: { x: 0, y: 0 } }

    this.fillTextMultiLine(this.ctx, s, coord.tl.x, coord.br.y + 20)
    this.ctx.restore()
  }

  private fillTextMultiLine(ctx: CanvasRenderingContext2D, text: string, x: number, y: number) {
    const lineHeight = ctx.measureText('M').width * 1.2
    const lines = text.split('\n')
    for (let i = 0; i < lines.length; i++) {
      ctx.fillText(lines[i], x, y + (i * lineHeight))
    }
  }

  public init() {
    this.watchRender()
  }
}
