import { fabric } from 'fabric'
import { v4 as guid } from 'uuid'
import { whiteboardConstants } from '@/models/constants'

interface IWbPathOptions extends fabric.IPathOptions, IWbObjectProps {
  id?: string
  lock?: boolean
  preventUnlock?: boolean
  preventDelete?: boolean
}

export default class WbPath extends fabric.Path implements IWbObject {
  public id: string
  public type = whiteboardConstants.objectTypes.path
  public lock: boolean
  public preventUnlock: boolean
  public connectable: boolean
  public preventDelete?: boolean
  public localOnly?: boolean
  public excludeFromGroupSelection?: boolean
  public editableProps: Record<string, IWbObjectProp> = {
    border: { name: 'border', type: 'border', editable: true },
    lock: { name: 'Lock', type: 'lock', editableWhenLocked: true, editable: true },
    addDiscussion: { name: 'add comment', type: 'addDiscussion', editable: true, editableWhenLocked: true },
  }

  public actions: Record<string, IWObjectActions> = {
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    bringFront: { action: 'bringFront', label: 'Bring to Front', faicon: 'fa-light fa-bring-front', showInSubMenu: true },
    sendBack: { action: 'sendBack', label: 'Send to Back', faicon: 'fa-light fa-send-back', showInSubMenu: true },
    group: { action: 'group', label: 'Group', faicon: 'fa-light fa-object-group', showInSubMenu: true, multiple: true },
    cloneAsImage: { action: 'cloneAsImage', label: 'Clone As Image', faicon: 'fa-light fa-clone', showInSubMenu: true },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
  }

  constructor(path?: string | fabric.Point[], opt?: IWbPathOptions) {
    super(path, opt)
    this.id = opt?.id || guid()
    this.lock = opt?.lock || false
    this.connectable = false // they will not attached to another connecters
    this.preventUnlock = opt?.preventUnlock || false
    this.preventDelete = opt?.preventDelete || false
    this.localOnly = opt?.localOnly || false
    this.excludeFromGroupSelection = opt?.excludeFromGroupSelection || false

    this.setLock(this.lock)

    this.stateProperties = this.stateProperties?.concat(['lock'])
  }

  setProp(prop: string, value: any) {
    switch (prop) {
      case 'border':
        this.set('stroke', value.borderColor)
        this.set('strokeWidth', value.strokeWidth)
        break
      case 'color':
        this.set('fill', value.backgroundColor)
        break
      case 'lock':
        this.set('lock', value.lock)
        this.setLock(value.lock)
        break
      default:
        console.warn('Attempting to set unsupported WbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'border':
        result.borderColor = this.stroke
        result.strokeWidth = this.strokeWidth
        break
      case 'color':
        result.backgroundColor = this.fill
        break
      case 'lock':
        result.lock = this.lock
        break
      default:
        console.warn('Attempting to get unsupported WbObjectProp', prop)
    }
    return result
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockRotation', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
  }

  override toObject(propertiesToInclude?: string[]) {
    const props = propertiesToInclude || []
    props.push('id', 'lock', 'preventUnlock')
    return super.toObject(props)
  }

  static fromObject(object: fabric.Object, callback?: Function) {
    return fabric.Object._fromObject(whiteboardConstants.objectTypes.path, object, callback, 'path') as WbPath
  }
}

const f: any = fabric
f.WbPath = WbPath
