<template>
  <tx-dialog
    v-model="visible" :title="t('favEditor.editFavorites')" width="600px" show-ok-cancel :ok-state="loading ? 'loading' : 'enabled'" :cancel-state="loading ? 'disabled' : 'enabled'" @closed="onClose"
    @ok="doConfirm" @cancel="doCancel"
  >
    <div class="inline-flex flex-wrap">
      <tx-alert class="w-full" :show="tags.length >= appConstants.limits.favoriteTags" type="warning" :text="t('favEditor.tagsLimit', appConstants.limits.favoriteTags)" />
      <div v-for="tag in tags" :key="tag.tag">
        <tag-editor
          v-if="editingTag && tag.id === editingTag.id" :tag="tag" :tags="tags" :show-delete-action="allowDeleteFavorite"
          @ok="onEditTag" @delete="onDeleteTag" @cancel="onCancelEditing"
        />
        <div
          v-else class="flex items-center p-2.5 pr-3 m-1 rounded-md check-box-tag h-10 cursor-pointer"
          :style="{ backgroundColor: tag.color + (tag.selected ? 'FF' : '33'), border: `1px solid ${tag.color}4C`, color: tag.selected ? 'white' : 'inherit' }"
          :class="{ cross: tag.deleted }"
        >
          <tx-checkbox v-model="tag.selected" class="cursor-pointer" :label="tag.tag" :indeterminate="tag.indeterminate" :disabled="tag.deleted" @change="tag.indeterminate = false" />
          <font-awesome-icon v-if="!tag.deleted" class="w-4 h-4 ml-1 hover:text-primary-500" icon="fa-light fa-pencil" @click="doEditTag(tag)" />
        </div>
      </div>

      <div
        v-show="!addingTag && tags.length < appConstants.limits.favoriteTags"
        data-testid="new-tag"
        class="flex items-center p-2 m-1 text-base border border-gray-200 border-solid rounded-md cursor-pointer hover:bg-blue-100 hover:text-primary-500 hover:border-blue-200 active:border-blue-50" @click="doShowAddTag"
      >
        <font-awesome-icon class="w-4 h-4 -ml-1 mr-0.5" icon="fa-light fa-plus" />
        <div class="pr-1 leading-5" v-text="t('favEditor.newTag')" />
      </div>
      <tag-editor v-model:show="addingTag" :tag="defaultTag" :tags="tags" @ok="onAddTag" />
    </div>
  </tx-dialog>
</template>

<script lang="ts" setup>
import { clone, filter, union, without } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import TagEditor from './TagEditor.vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxCheckbox from '@/shared/components/TxCheckbox.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import type MyArticle from '@/models/myArticle'
import FavoriteTag from '@/models/favoriteTag'
import { appConstants, privileges } from '@/models/constants'
import appConfig from '@/services/appConfig'
import { useUserStore } from '@/store/userData'
import { useBrowseByStore } from '@/store/browseBy'
import useEventsBus from '@/shared/composables/eventBus'

const { t } = useI18n()
const visible = ref(false)
const userStore = useUserStore()
const tags = ref<ITag[]>([])
const originalTags = {} as { [tag: string]: { selected: boolean, indeterminate: boolean, tag: FavoriteTag } }
const addingTag = ref(false)
const editingTag = ref<ITag>()
const browseByStore = useBrowseByStore()
const { emitter } = useEventsBus()

const loading = ref(false)
let articleIds = [] as number[]
const defaultTag: ITag = { tag: '', color: '', selected: true, indeterminate: false, deleted: false, articles: articleIds }

async function showDialog(articles: MyArticle[]) {
  visible.value = true
  tags.value = []
  articleIds = articles.map(itm => itm.Id)
  let articlesList: MyArticle[] = articles
  if (browseByStore.isBrowseByModel) { // add all the articles of selected Articles
    if (!userStore || (!userStore.activeCatalog)) {
      console.warn('Unable to load model details by modelNumber')
      return null
    }
    const catalogDetails = userStore.activeCatalog
    const modelNumbers = articles.map(s => s.ModelNumber)
    const modelArticles = await appConfig.DB!.getMyArticlesByModelNumbers(modelNumbers, catalogDetails, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
    if (modelArticles && modelArticles.length) {
      articlesList = modelArticles
      articleIds = modelArticles.map(itm => itm.Id)
    }
  }
  const favs = await appConfig.DB!.favoriteTags.where({ CatalogCode: userStore.activeCatalog!.CatalogCode, CreatedByUserName: userStore.currentUsername, Status: 1 }).toArray()
  favs.forEach((itm) => {
    const matches = filter(articlesList, art => itm.Articles.includes(art.Id))
    const allSelected = matches.length === articles.length
    const someSelected = matches.length > 0
    tags.value.push({
      id: itm.Id,
      tag: itm.Tag,
      color: itm.Color,
      selected: allSelected,
      indeterminate: !allSelected && someSelected,
      articles: itm.Articles,
      deleted: false,
    })
    originalTags[itm.Tag] = { tag: itm, selected: allSelected, indeterminate: !allSelected && someSelected }
  })
}

const allowDeleteFavorite = computed(() => {
  return userStore.userProfile.isValidPrivilege(privileges.favorite.updateStatus)
})

function doCancel() {
  visible.value = false
  onCancelEditing()
}

function onEditTag(tag: ITag) {
  if (editingTag.value) {
    editingTag.value.oldTag = clone(editingTag.value)
    editingTag.value.id = tag.id
    editingTag.value.tag = tag.tag
    editingTag.value.color = tag.color
  }
  onCancelEditing()
}

async function doConfirm() {
  if (userStore.activeCatalog) {
    loading.value = true

    const promises: Promise<void>[] = []
    const deletedTags: FavoriteTag[] = []
    const tagsToCreate: FavoriteTag[] = []
    // Find the tags that were changed
    tags.value.forEach((itm) => {
      const tag = originalTags[itm.tag]?.tag || new FavoriteTag(userStore.activeCatalog!.CatalogCode, { Id: itm.id, Tag: itm.tag, Color: itm.color, CreatedByUserName: userStore.currentUsername, Articles: itm.articles })
      if (itm.deleted && itm.id) {
        tag.Status = 0
        tag.LocalUpdatedDate = new Date()
        deletedTags.push(tag)
      }
      else if (!originalTags.hasOwnProperty(itm.tag) || originalTags[itm.tag].tag.Tag !== itm.tag || originalTags[itm.tag].tag.Color !== itm.color
        || originalTags[itm.tag].indeterminate !== itm.indeterminate || originalTags[itm.tag].selected !== itm.selected
      ) {
        tag.Articles = itm.selected ? union(tag.Articles, articleIds) : without(tag.Articles, ...articleIds)
        tag.Color = itm.color
        tag.LocalUpdatedDate = new Date()
        tagsToCreate.push(tag)
        // promises.push(appConfig.DB!.createUpdateFavorite(userStore.activeCatalog!.CatalogCode, tag))
        if (itm.oldTag) {
          const oldTag = new FavoriteTag(userStore.activeCatalog!.CatalogCode, { Id: itm.oldTag.id, Tag: itm.oldTag.tag, Color: itm.oldTag.color, CreatedByUserName: userStore.currentUsername, Articles: itm.oldTag.articles })
          oldTag.Articles = without(oldTag.Articles, ...articleIds)
          oldTag.LocalUpdatedDate = new Date()
          oldTag.Status = 0
          deletedTags.push(oldTag)
          // promises.push(appConfig.DB!.deleteFavorite(userStore.activeCatalog!.CatalogCode, deletedTags))
        }
      }
    })

    if (deletedTags.length) {
      await appConfig.DB!.deleteFavorite(userStore.activeCatalog.CatalogCode, deletedTags)
    }
    if (tagsToCreate.length) {
      tagsToCreate.forEach((tag) => {
        promises.push(appConfig.DB!.createUpdateFavorite(userStore.activeCatalog!.CatalogCode, tag))
      })
      await Promise.all(promises)
    }
    // update filter lookup filter after updating the tags
    const favoriteTagsAtt = userStore.myAttributes!._FavoriteTags
    if (favoriteTagsAtt) {
      const favTags = await appConfig.DB!.favoriteTags.where({ CatalogCode: userStore.activeCatalog!.CatalogCode, CreatedByUserName: userStore.currentUsername, Status: 1 }).toArray()
      favoriteTagsAtt.FilterLookup = new Map()
      favTags.forEach((favoriteTag) => {
        if (!favoriteTagsAtt.FilterLookup.has(favoriteTag.Id)) {
          favoriteTagsAtt.FilterLookup.set(favoriteTag.Id, favoriteTag.Tag)
        }
      })
      emitter('catalogDataUpdated', { source: 'Favorite' })
    }
    loading.value = false
    visible.value = false
  }
}

function doShowAddTag() {
  addingTag.value = true
  onCancelEditing()
}

function onAddTag(tag: ITag) {
  tags.value.push(tag)
}

function doEditTag(tag: ITag) {
  if (tag.deleted) { return }

  editingTag.value = tag
  addingTag.value = false
}

function onDeleteTag(tag: ITag) {
  tag.deleted = true
  onCancelEditing()
}

function onCancelEditing() {
  editingTag.value = undefined
}

function onClose() {
  addingTag.value = false
  onCancelEditing()
}

defineExpose({
  showDialog,
})
</script>

<style lang="scss">
.check-box-tag {
  &.is-checked {
    .el-checkbox__label {
      color: white !important;
    }
  }
}
.cross {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M1 0 L0 1 L99 100 L100 99' fill='black' /><path d='M0 99 L99 0 L100 1 L1 100' fill='black' /></svg>");
  background-repeat:no-repeat;
  background-position:center center;
  background-size: 100% 100%, auto;
}
</style>
