<template>
  <div class="container relative flex flex-col w-full h-full overflow-hidden">
    <div class="header" />
    <div class="relative mt-4 w-full h-full overflow-y-auto">
      <div v-if="article._Resources?.length" class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        <resource-item
          v-for="resource in article._Resources"
          :key="resource.Id"
          type="card"
          class="cursor-pointer hover:bg-gray-100"
          :resource="resource"
          @click="onResourceClick(resource)"
        />
      </div>
      <div v-else class="flex items-center justify-center col-start-1 col-end-12">
        <p>{{ t('general.emptyList') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import ResourceItem from '@/shared/components/resources/ResourceItem.vue'
import type MyArticle from '@/models/myArticle'
import type Resource from '@/models/resource'

defineProps<{
  article: MyArticle
}>()

const { t } = useI18n()

function onResourceClick(resource: Resource) {
  window.open(resource.ResourceUrl)
}
</script>
