// TODO: Move all t1 article related API calls here
import type { ArticleDetailsModel, ArticleDropReasonModel, ArticleForecastModel, ArticleHistoryModel, ArticleModel, ArticleSegmentationModel, ArticleStateModel, AssignedModelListItem, ModelArticleModel, ModelStatsModel, SeasonsDataModel, UpdateArticleForecastQuantityModel, UpdateArticlePricesModel, UpdateArticleSegmentationsModel, UpdateArticlesLockModel, UpdateArticlesStatusModel, UpdateParentModelInfoModel } from './model/articleModel'
import type { LinkedCatalogArticleModel } from './model/LinkedCatalogArticleModel'
import Net from '@/services/net'
import type DuneAsset from '@/models/duneAsset'
import utils from '@/services/utils'
import { backgroundJobConstants } from '@/models/constants'

/**
 * Get list of articles
 * @param catalogCode
 * @param filter filter expression (ArticleNumber|123456-001:ModelNumber|123456)
 * @param u toISOString date
 * @param attr comma separated attribute system names
 * @returns ArticleModel[]
 */
export function getArticles(catalogCode: number, filter?: string, u?: string, attr?: string, dataSourceTypeId?: number) {
  const params = new URLSearchParams()
  if (utils.isDefined(filter)) {
    params.set('filter', filter)
  }
  if (utils.isDefined(u)) {
    params.set('u', u)
  }
  if (utils.isDefined(attr)) {
    params.set('attr', attr)
  }
  let url = `/catalogs/${catalogCode}/articles`
  if (dataSourceTypeId === 3) {
    url = `/catalogs/${catalogCode}/assortablearticles`
  }
  return Net.t1.get<ArticleModel[]>(`${url}?${params.toString()}`)
}

export function getModelArticles(sellerAccountId: number, attributeGroupId: number, modelId: number, catalogCodes: number[], articleId?: number) {
  const params = new URLSearchParams()
  params.set('catalogs', catalogCodes.join(','))
  if (utils.isDefined(articleId)) {
    params.set('articleid', articleId.toString())
  }
  return Net.t1.get<ModelArticleModel[]>(`/sellers/${sellerAccountId}/attributegroups/${attributeGroupId}/models/${modelId}/articles?${params.toString()}`)
}

export function getLinkedCatalogArticles(catalogCode: number, modelId: number, linkedCatalogCodeList: number[]) {
  const params = new URLSearchParams()
  params.set('linkedcatalogs', linkedCatalogCodeList.join(','))
  return Net.t1.get<LinkedCatalogArticleModel[]>(`/catalogs/${catalogCode}/models/${modelId}/articles?${params.toString()}`)
}

export function getArticleHistory(catalogCode: number, articleId: number) {
  return Net.t1.get<ArticleHistoryModel[]>(`/catalogs/${catalogCode}/articles/${articleId}/history`)
}

export function createArticle(catalogCode: number, requestObject: Record<string, any>, createModelFlag: number, requestId?: number, shouldConfirmRequest = false) {
  const params = new URLSearchParams()
  if (createModelFlag === 1) {
    params.set('createmodel', '1')
  }
  else if (createModelFlag === 2) {
    params.set('createchildmodel', '1')
  }
  else {
    params.set('createmodel', '0')
  }
  if (requestId) {
    params.set('requestid', requestId.toString())
  }
  if (shouldConfirmRequest) {
    params.set('confirmrequest', 'true')
  }
  return Net.t1.post(`/catalogs/${catalogCode}/models/articles?${params.toString()}`, requestObject)
}

export function assignArticle(catalogCode: number, requestObject: Record<string, any>, isCreateChildModel: boolean, modelId: number, requestId?: number, shouldConfirmRequest = false) {
  const params = new URLSearchParams()
  if (isCreateChildModel) {
    params.set('createchildmodel', '1')
    if (utils.isDefined(modelId)) {
      params.set('NewModelId', modelId.toString())
    }
  }
  if (requestId) {
    params.set('requestid', requestId.toString())
  }
  if (shouldConfirmRequest) {
    params.set('confirmrequest', 'true')
  }
  return Net.t1.post(`/catalogs/${catalogCode}/models/assign/articles?${params.toString()}`, requestObject)
}

export function updateArticle(catalogCode: number, articleId: number, requestObject: Record<string, any>, updateModelLevelAttributes = false, requestId: number | null = null) {
  let url = `/catalogs/${catalogCode}/articles/${articleId}?clientapp=t1sweb&updateModelLevelAttributes=${updateModelLevelAttributes}`
  if (requestId !== null) {
    url = `${url}&requestid=${requestId}`
  }
  return Net.t1.put(url, requestObject)
}

export function updateArticlesStatus(catalogCode: number, requestObject: UpdateArticlesStatusModel[], requestId: number | null = null) {
  let url = `/catalogs/${catalogCode}/articles/status`
  if (requestId !== null) {
    url = `${url}?requestid=${requestId}`
  }
  return Net.t1.put(url, requestObject)
}

export function updateArticlesLock(catalogCode: number, isLock: boolean, requestObject: UpdateArticlesLockModel[]) {
  if (isLock) {
    return Net.t1.put(`/catalogs/${catalogCode}/articles/lock`, requestObject)
  }
  else {
    return Net.t1.put(`/catalogs/${catalogCode}/articles/unlock`, requestObject)
  }
}

export function updateArticleShipmentWindowRange(catalogCode: number, articleId: number, retailWindowId: number | '') {
  // '' will remove the shipment window from article
  return Net.t1.put(`/catalogs/${catalogCode}/articles/${articleId}/shipmentwindowrange`, { ShipmentWindowRangeId: retailWindowId })
}

export function updateArticleSegmentations(catalogCode: number, articleId: number, requestObject: UpdateArticleSegmentationsModel[]) {
  return Net.t1.put<ArticleSegmentationModel[]>(`/catalogs/${catalogCode}/articles/${articleId}/articlesegmentations`, requestObject)
}

export function getArticleForecasts(catalogCode: number, customerId: number, articleId: number) {
  return Net.t1.get<ArticleForecastModel[]>(`/catalogs/${catalogCode}/forecasts/article?customerid=${customerId}&articleid=${articleId}`)
}

export function updateArticleForecastQuantity(catalogCode: number, articleId: number, requestObject: UpdateArticleForecastQuantityModel[]) {
  return Net.t1.put(`/catalogs/${catalogCode}/forecasts/articles/${articleId}`, requestObject)
}

export function getArticleDropReasons(sellerId: number) {
  return Net.t1.get<ArticleDropReasonModel[]>(`/sellers/${sellerId}/articles/reasons`)
}

export function getArticleNumberToBeAssigned(catalogCode: number, colorId: number, modelId: number, articleId: number) {
  return Net.t1.get(`/catalogs/${catalogCode}/colors/${colorId}?modelid=${modelId}&articleid=${articleId}`)
}

export function updateArticlePrices(catalogCode: number, recordId: number | string, payload: UpdateArticlePricesModel, isPriceByStyleApply: number, requestId: number | null = null) {
  let url = `catalogs/${catalogCode}/articles/${recordId}/articleprices`

  if (isPriceByStyleApply) {
    url = `catalogs/${catalogCode}/modelnumber/${recordId}/prices`
  }

  if (requestId !== null) {
    url = `${url}?requestid=${requestId}`
  }
  return Net.t1.put(url, payload)
}

export function getArticleStates(sellerAccountId: number, attributeGroupId: number) {
  return Net.t1.get<ArticleStateModel[]>(`sellers/${sellerAccountId}/attributegroups/${attributeGroupId}/articlestates`)
    .then((response) => {
      // sort States by StateRank
      const sortedData = response.data.sort((a, b) => a.StateRank - b.StateRank) as Array<ArticleStateModel>
      return sortedData
    })
}

export function getModelStats(catalogCode: number, modelId: number) {
  return Net.t1.get<ModelStatsModel[]>(`/catalogs/${catalogCode}/models/${modelId}/stats`)
}

export function updateArticlesState(catalogCode: number, articles, stateId: number | null, requestId: number | null = null) {
  const payload = articles.reduce((acu, cur) => (acu.push({ ArticleId: cur.Id, StateId: stateId })) && acu, [])
  let url = `catalogs/${catalogCode}/articles/articlestates`
  if (requestId !== null) {
    url = `${url}?requestid=${requestId}`
  }
  return Net.t1.put(url, payload)
}

export function updateParentModelInfo(sellerId: number, attributeGroupId: number, payload: UpdateParentModelInfoModel[]) {
  return Net.t1.put(`sellers/${sellerId}/${attributeGroupId}/modifyparentmodelinfo`, payload)
}

export function updateModelSeasonlessAttributes(sellerId: number, attributeGroupId: number, modelId: number, requestObject: Record<string, any>) {
  const url = `/sellers/${sellerId}/${attributeGroupId}/models/${modelId}?clientapp=t1sweb`
  return Net.t1.put(url, requestObject)
}

export function getAssignedCatalogModels(catalogCode: number) {
  return Net.t1.get<AssignedModelListItem[]>(`/catalogs/${catalogCode}/true/models/assigned`)
}

export function updateModelAttributes(catalogCode: number, modelId: number, requestObject: Record<string, any>) {
  const url = `/catalogs/${catalogCode}/true/models/${modelId}?clientapp=t1sweb`
  return Net.t1.put(url, requestObject)
}

export function getArticleAssets(context: string, contextKey: string, articleNumber: string, newestImageAssetKeyList: string[] = [], firstImage: boolean = false) {
  return Net.dunes.get<DuneAsset[]>(`/assets`, { params: { Context: context, ContextKey: contextKey, ImageSet: articleNumber, Status: true, FirstImage: firstImage } })
    .then((response) => {
      let assets: Array<DuneAsset> = []
      if (response && response.data) {
        assets = utils.sort(response.data, ['SortOrder'])
        if (newestImageAssetKeyList.length) {
          const newestImageAssetKeySet = new Set(newestImageAssetKeyList.map(x => x.toLowerCase()))
          if (assets.findIndex(a => utils.isDefined(a.Key) && newestImageAssetKeySet.has(a.Key.toLowerCase())) >= 0) {
            assets = assets.sort((a, b) => {
              const hasAKey = newestImageAssetKeySet.has(a.Key?.toLowerCase())
              const hasBKey = newestImageAssetKeySet.has(b.Key?.toLowerCase())

              if (hasAKey === hasBKey) {
                return new Date(b.UpdatedOn).getTime() - new Date(a.UpdatedOn).getTime()
              }

              return hasAKey ? -1 : 1
            })
          }
        }
      }
      return assets
    })
}

export function getArticlesAssets(context: string, contextKey: string, articleNumbers: string[], firstImage: boolean = false) {
  return Net.dunes.post<DuneAsset[]>(`/assets/q`, { Context: context, ContextKey: contextKey, Op: 'equals', Filter: articleNumbers, Status: true, FirstImage: firstImage, Type: 'ImageSet' })
}

export function articlesBgJob(catalogCode: number, requestObject: Record<string, any>, isCarryover = false) {
  const params = new URLSearchParams()
  params.set('sourceapplication', backgroundJobConstants.sourceApplication)
  if (isCarryover) {
    params.set('sourceaction', backgroundJobConstants.sourceAction.carryoverArticles)
    params.set('carryover', '1')
  }
  else {
    params.set('sourceaction', backgroundJobConstants.sourceAction.copyModel)
  }
  return Net.t1.put(`/catalogs/${catalogCode}/articles?${params.toString()}`, requestObject)
}

export function assignArticleColors(catalogCode: number, articleId: number, requestObject: Record<string, any>) {
  return Net.t1.put(`/catalogs/${catalogCode}/articles/${articleId}/assignArticleColors`, requestObject)
}

export function assortArticlesBgJob(catalogCode: number, requestArticles: Record<string, any>) {
  return Net.t1.put(`/catalogs/${catalogCode}/articles?sourceapplication=${backgroundJobConstants.sourceApplication}&sourceaction=${backgroundJobConstants.sourceAction.assortArticles}`, requestArticles)
}

export function updateViaImportBgJob(catalogCode: number, requestArticles: Record<string, any>) {
  return Net.t1.put(`/catalogs/${catalogCode}/updatearticles?sourceapplication=${backgroundJobConstants.sourceApplication}&sourceaction=${backgroundJobConstants.sourceAction.assortArticles}`, requestArticles)
}

export function updateViaImportModelBgJob(catalogCode: number, requestArticles: Record<string, any>) {
  return Net.t1.put(`/catalogs/${catalogCode}/importbymodels?sourceapplication=${backgroundJobConstants.sourceApplication}&sourceaction=${backgroundJobConstants.sourceAction.assortArticles}`, requestArticles)
}

export function importByLicenseeBgJob(catalogCode: number, requestArticles: Record<string, any>) {
  return Net.t1.put(`/catalogs/${catalogCode}/articles?importbylicensee=true&sourceapplication=${backgroundJobConstants.sourceApplication}&sourceaction=${backgroundJobConstants.sourceAction.assortArticles}`, requestArticles)
}

export function setSizesByLicenseeModelNumberBgJob(catalogCode: number, requestData: Record<string, any>) {
  return Net.t1.put(`/catalogs/${catalogCode}/licenseemodels/assignsizescales?sourceapplication=${backgroundJobConstants.sourceApplication}&sourceaction=${backgroundJobConstants.sourceAction.assortArticles}`, requestData)
}

export function setPricesByLicenseeModelNumberBgJob(catalogCode: number, requestData: Record<string, any>) {
  return Net.t1.put(`/catalogs/${catalogCode}/licenseemodels/assignprices?sourceapplication=${backgroundJobConstants.sourceApplication}&sourceaction=${backgroundJobConstants.sourceAction.assortArticles}`, requestData)
}

export function getModelPrices(catalogCode: number, modelId: number) {
  return Net.t1.get(`/catalogs/${catalogCode}/models/${modelId}/prices`)
}

export function getSeasonsData(articleId: number) {
  return Net.t1.get<SeasonsDataModel[]>(`/catalogs/articles/${articleId}/seasondata`)
}

export function getArticleDetails(catalogCode: number, articleId: number) {
  return Net.t1.get<ArticleDetailsModel>(`/catalogs/${catalogCode}/articles/${articleId}`)
}
